import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend, Label} from 'recharts'

import { changeMapDay, generateMapData} from '../../actions/map'
import { toggleLoader, generatingMapData} from '../../actions/global'


import CustomizedAxisTick from './CustomizedAxisTick';

class CustomTooltip extends Component{
  setString(item){
    if(item === 'stddv_pos'){
      return 'Média + DP'
    }else if(item === 'stddv_neg'){
      return 'Média - DP'
    }else if(item === 'mean'){
      return 'Média'
    }else{
      return item.toUpperCase()
    }
  }

  setMaturationData(payload, compareYears){
    let top = payload[0].value
    let mean = payload[1].value
    let bottom = payload[2].value
    let data = []
    let value = 0
    compareYears.forEach(year => {
      if(payload[0].payload['atr_' + year] !== undefined){
        value = payload[0].payload['atr_' + year]

        if(value > top){
          data.push({'key': 'Maturação ' + year, 'value': 'Boa'})
        }else if(value <= top && value >= mean){
          data.push({'key': 'Maturação ' + year, 'value': 'Média'})
        }else if(value <= mean && value >= bottom){
          data.push({'key': 'Maturação ' + year, 'value': 'Baixa'})
        }else if(value < bottom){
          data.push({'key': 'Maturação ' + year, 'value': 'Muito Baixa'})
        }
      }
    })

    return data
  }

  render() {
    const { active, showTooltips, visualization } = this.props;

    if (active && showTooltips) {
      const { payload, label, compareYears, type,} = this.props;
      let maturation = this.setMaturationData(payload,compareYears)
      if(type === 'atr'){
        if(compareYears.length === 1){
          return (
            <div className="custom-tooltip-atr">
              <p className="label">{`${label}`}</p>
              {payload.map(item => {
                let string = this.setString(item.dataKey)
                return(
                  <p style={{color: item.stroke}} className="desc">{string + `: ${item.value} Kg/T`}</p>
                )
              })}
              {maturation.map(item =>{
                return(
                  <p style={{color: '#000'}} className="desc">{`${item.key}: ${item.value}`}</p>
                )
              })}
            </div>
          )
        }else{
          return (
            <div className="custom-tooltip-atr">
              <p className="label">{`${label}`}</p>
              {payload.map(item => {
                let string = this.setString(item.dataKey)
                if(item.dataKey === 'stddv_pos' || item.dataKey === 'stddv_neg'){
                  return null
                }else{
                  const shouldIncrement = visualization !== 'civil' && string.slice(0, 3) === 'ATR';
                  const incrementedValue = shouldIncrement ? '/' + (Number(string.slice(-2))) : '';
                  const displayText = `${visualization !== 'civil' && string.slice(0, 3) === 'ATR' ? `ATR_${Number(string.slice(-4) - 1)}` : string}${incrementedValue}: ${item.value} Kg/T`;

                  return <p style={{ color: item.stroke }} className="desc">{displayText}</p>;
                }
              })}
              {maturation.map(item =>{
                return(
                  <p style={{color: '#000'}} className="desc">{`${item.key}: ${item.value}`}</p>
                )
              })}
            </div>
          )
        }
      }else{
        return (
          <div className="custom-tooltip-atr">
            <p className="label">{`${label}`}</p>
            {payload.map(item => {
              let string = this.setString(item.dataKey)
              return(
                <p style={{color: item.stroke}} className="desc">{string +  `: ${item.value} mm`}</p>
              )
            })}
          </div>
        )
      }
    }
    return null;
  }
}

class AtrMax extends Component{
  setYValues(data, variable){
    let maxVariable = 0
    let baseValue = 0
    let maxDP = Math.max.apply(Math, data.map(object => {return object.stddv_pos}))
    if(variable === 'atr'){
      maxVariable = Math.max.apply(Math, data.map(object => {return object.atr}))
      baseValue = 50
    }else{
      maxVariable = Math.max.apply(Math, data.map(object => {return object.p}))
      baseValue = 200
    }
    let maxValue = maxVariable >= maxDP ? parseInt(maxVariable,10) : parseInt(maxDP,10)

    let remain = maxValue % baseValue
    let diffValue = 0
    if(remain !== 0){
      diffValue = baseValue - remain
      maxValue += diffValue
    }else{
      maxValue += remain
    }

    let intervalNumber = maxValue / baseValue

    return([maxValue, intervalNumber + 1])
  }

  setColors(idx){
    if(idx === 0){
      return '#FF0000'
    }else{
      return '#0000FF'
    }
  }

  setLines(compareYears, type){
    let max = compareYears.reduce((a, b) => {
      return this.props.visualizationType === 'civil'
        ? Math.max(parseInt(a,10), parseInt(b,10))
        : Math.max(parseInt(a,10), parseInt(b,10)) - 1;
    });

    let lines = []
    let line = undefined
    if(type === 'atr'){
      compareYears.forEach((year,idx) => {
        line = <Line
          key={'atr_' + year}
          type="monotone"
          connectNulls={true}
          onClick={this.handleLineClick}
          dataKey={'atr_' + year}
          strokeWidth={2}
          stroke={
            this.props.visualizationType === 'civil'
              ? this.setColors(parseInt(year,10) !== max ? 1 : 0)
              : this.setColors(parseInt(year,10) !== max ? 0 : 1)
          }
          dot={false}
          activeDot={false}
          isAnimationActive={false}
        />
        lines.push(line)
        line = undefined
      })
    }else{
      compareYears.forEach((year,idx) => {
        line = <Line
          key={'p_' + year}
          type="monotone"
          connectNulls={true}
          onClick={this.handleLineClick}
          dataKey={'p_' + year}
          strokeWidth={2}
          stroke={
            this.props.visualizationType === 'civil'
              ? this.setColors(parseInt(year,10) !== max ? 1 : 0)
              : this.setColors(parseInt(year,10) !== max ? 0 : 1)
          }
          dot={false}
          activeDot={false}
          isAnimationActive={false}
        />
        lines.push(line)
        line = undefined
      })
    }
    return lines
  }
  

  setName(month, day, referenceLine){
    let dayString = ''

    if(day < 10){
      dayString = '0'+(day).toString()
    }else{
      dayString = (day).toString()
    }

    if(month === 0){
        if(referenceLine){
        }else if(day === 0){
          return 'JAN'
        }
        return dayString + '/0' + (month+1).toString()
    }else if(month === 1){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 2){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 3){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 4){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 5){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 6){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 7){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 8){
        return dayString + '/0' + (month+1).toString()
    }else if(month === 9){
        return dayString + '/' + (month+1).toString()
    }else if(month === 10){
        return dayString + '/' + (month+1).toString()
    }else if(month === 11){
        return dayString + '/' + (month+1).toString()
    }
  }

  render(){
    let data = []
    let data_p = []

    for(let i = this.props.fromIndex-1; i <= this.props.toIndex-1; i++){
      data.push(this.props.data[0].bhbox[0].data[i])
      data_p.push(this.props.data[0].bhbox[0].p_data[i])
    }

    let atrLines = this.setLines(this.props.compareYears, 'atr')
    let pLines = this.setLines(this.props.compareYears, 'p')

    let payload_atr = [
      {value: 'ATR Média', type: 'line', id: 'atr', color: '#006400' },
      {value: 'ATR Média - DP', type: 'line', id: 'stddv_neg', color: '#BDBDBD'},
      {value: 'ATR Média + DP', type: 'line', id: 'stddv_pos', color: '#BDBDBD'},
    ]
    let payload_p = [
      {value: 'Chuva Acumulada Média', type: 'line', id: 'atr', color: '#006400' },
      {value: 'Chuva - DP', type: 'line', id: 'stddv_neg', color: '#BDBDBD'},
      {value: 'Chuva + DP', type: 'line', id: 'stddv_pos', color: '#BDBDBD'},
    ]

    let max = this.props.compareYears.reduce((a, b) => {
      return Math.max(parseInt(a,10), parseInt(b,10))
    })

    if (this.props.visualizationType === 'agricultural') {
      data = data.map((d) => {
        const month = Number(d.name.split('/')[1]);
        const agriculturalData = {
          mean: d.mean,
          name: d.name,
          stddv_neg: d.stddv_neg,
          stddv_pos: d.stddv_pos,
        }

        return month > 6
          ? {
            ...agriculturalData,
            [`atr_${max}`]: d[`atr_${max-1}`],
            [`atr_${max-1}`]: d[`atr_${max-2}`],
          }
          : {
            ...agriculturalData,
            [`atr_${max}`]: d[`atr_${max}`],
            [`atr_${max-1}`]: d[`atr_${max-1}`],
          }
      });

      data_p = data_p.map((d) => {
        const month = Number(d.name.split('/')[1]);
        const agriculturalData = {
          mean: d.mean,
          name: d.name,
          stddv_neg: d.stddv_neg,
          stddv_pos: d.stddv_pos,
        }

        return month > 6
          ? {
            ...agriculturalData,
            [`p_${max}`]: d[`p_${max-1}`],
            [`p_${max-1}`]: d[`p_${max-2}`],
          }
          : {
            ...agriculturalData,
            [`p_${max}`]: d[`p_${max}`],
            [`p_${max-1}`]: d[`p_${max-1}`],
          }
      });
    }

    this.props.compareYears.forEach(year => {
      if (this.props.visualizationType === 'civil') {
        if(parseInt(year, 10) === max){
          payload_atr.push({value: 'ATR_' + year, type: 'line', id: 'atr_' + year, color: '#FF0000'})
          payload_p.push({value: 'Chuva Acumulada ' + year, type: 'line', id: 'p_' + year, color: '#FF0000'})
        }else{
          payload_atr.push({value: 'ATR_' + year, type: 'line', id: 'atr_' + year, color: '#0000FF'})
          payload_p.push({value: 'Chuva Acumulada ' + year, type: 'line', id: 'p_' + year, color: '#0000FF'})
        }
      } else {
        const pastYear = Number(year) - 1;
        const currentYear = year.substring(2);

        if(parseInt(year, 10) === max){
          payload_atr.push({value: `ATR_${pastYear}/${currentYear}`, type: 'line', id: 'atr_' + year, color: '#FF0000'})
          payload_p.push({value: `Chuva acumulada ${pastYear}/${currentYear}`, type: 'line', id: 'p_' + year, color: '#FF0000'})
        }else{
          payload_atr.push({value: `ATR_${pastYear}/${currentYear}`, type: 'line', id: 'atr_' + year, color: '#0000FF'})
          payload_p.push({value: `Chuva Acumulada ${pastYear}/${currentYear}`, type: 'line', id: 'p_' + year, color: '#0000FF'})
        }
      }
    })

    let atrYAxisValues = [180, 10];
    let pYAxisValues = this.setYValues(data_p, 'p');

    return(
      <Fragment>
          {this.props.currentGraphic === 1
            ? (
              <ResponsiveContainer width="100%" height="87%">
                <LineChart data={data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                  <XAxis dataKey="name" tick={<CustomizedAxisTick/>} height={45} interval={"preserveStartEnd"} tickCount={36}/>
                  <YAxis type='number' orientation='left' tick={{fontSize: '0.95rem'}} interval={0} tickCount={atrYAxisValues[1]} domain={[0, atrYAxisValues[0]]}>
                    <Label angle={-90} value={'(Kg / T)'} position='insideLeft' style={{textAnchor: 'middle'}} />
                  </YAxis>
                  <CartesianGrid strokeDasharray="1 1"/>
                  <Tooltip content={<CustomTooltip compareYears={this.props.compareYears} type={'atr'}/>} showTooltips={this.props.showTooltips} visualization={this.props.visualizationType}/>
                  <Line key={'stddv_pos'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'stddv_pos'} strokeWidth={2} stroke={'#BDBDBD'} dot={false} activeDot={false} isAnimationActive={false}/>
                  <Line key={'mean'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'mean'} strokeWidth={2} stroke={'#006400'} dot={false} activeDot={false} isAnimationActive={false}/>
                  <Line key={'stddv_neg'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'stddv_neg'} strokeWidth={2} stroke={'#BDBDBD'} dot={false} activeDot={false} isAnimationActive={false}/>
                  {atrLines.map(line => {
                    return line
                  })}
                  <Legend margin={{top: 20, left: 0, right: 0, bottom: 30}}
                    align='center'
                    verticalAlign='top'
                    payload={payload_atr}
                    height={26}
                  />
                </LineChart>
              </ResponsiveContainer>
            )
            : (
              <ResponsiveContainer width="100%" height="87%">
                <LineChart data={data_p} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                  <XAxis dataKey="name" tick={<CustomizedAxisTick/>} height={45} interval={"preserveStartEnd"} tickCount={36}/>
                  <YAxis type='number' orientation='left' tick={{fontSize: '0.95rem'}} interval={0} tickCount={pYAxisValues[1]} domain={[0, pYAxisValues[0]]}>
                    <Label angle={-90} value={'(mm)'} position='insideLeft' style={{textAnchor: 'middle'}} />
                  </YAxis>
                  <CartesianGrid strokeDasharray="1 1"/>
                  <Tooltip content={<CustomTooltip compareYears={this.props.compareYears} type={'p'}/>} showTooltips={this.props.showTooltips}/>
                  <Line key={'p_stddv_pos'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'stddv_pos'} strokeWidth={2} stroke={'#BDBDBD'} dot={false} activeDot={false} isAnimationActive={false}/>
                  <Line key={'p_mean'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'mean'} strokeWidth={2} stroke={'#006400'} dot={false} activeDot={false} isAnimationActive={false}/>
                  <Line key={'p_stddv_neg'} type="monotone" connectNulls={true} onClick={this.handleLineClick} dataKey={'stddv_neg'} strokeWidth={2} stroke={'#BDBDBD'} dot={false} activeDot={false} isAnimationActive={false}/>
                  {pLines.map(line => {
                    return line
                  })}
                  <Legend margin={{top: 20, left: 0, right: 0, bottom: 0}}
                    align='center'
                    verticalAlign='top'
                    payload={payload_p}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    reportTypes: state.Reports.reportTypes,
    activeDay: state.map.weatherMapDay,
    lastUsedCad: state.Reports.lastUsedCad,
    showTooltips: state.global.showTooltips,
    visualizationType: state.global.visualizationType,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMapDay: (day) => {
      dispatch(changeMapDay(day))
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes,attrs))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    generatingMapData: () => {
      dispatch(generatingMapData())
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AtrMax)
