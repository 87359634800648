import React, { Component, Fragment } from "react";
import classnames from "classnames";
import moment from "moment";
import ReportsApi from "../api/reports";

// Components
import Bhbox from "../components/reports/bhbox/Bhbox";
import AgryIndex from "../components/reports/index/AgryIndex";
import AgryFire from "../components/reports/fire/AgryFire";
import GraphicChart from "../components/reports/graphic/GraphicChart";
import BhboxDropdown from "../components/reports/bhbox/BhboxDropdown";
import CadDropdown from "../components/reports/bhbox/CadDropdown";
import WeatherGraphic from "../components/reports/weather/WeatherGraphic";
import TemperatureDropdown from "../components/reports/weather/TemperatureDropdown";
import WeatherDropdown from "../components/reports/weather/WeatherDropdown";
import AtrMax from "../components/reports/AtrMax";
import AtrMaxHelp from "../components/reports/AtrMaxHelp";
import ReportFooter from "./ReportFooter";
import { showToaster } from "./common/Toaster";
import EmailModal from "./common/EmailModal";
import VisualizationDropdown from "./reports/productivity/VisualizationDropdown";
import MonthSelect from "./MonthSelect";

//utils
import {
  numberToMonth,
  monthToNumber,
  leapYear,
  setDates,
  setDecendialNumber,
  agriculturalYearSubtraction,
  setLabeltoDate,
} from "../shared/utils/DatesUtils";
import DownloadXLS from "../shared/utils/DownloadXLS";
import { activePointChange } from "../shared/utils/ProductivityAccess";
import { AGRYTOOLS } from "../shared/utils/consts";

// Styles
import "../stylesheets/css/components/report_container.css";
import { TextField, Select, MenuItem } from "@material-ui/core";
import { Button } from '@mui/material';
import { ClimateFavorabilityReportChart } from "./reports/index/ClimateFavorabilityReportChart";
import { Box, LinearProgress } from "@mui/material";
import SelectGraphicAtrMax from "./reports/SelectGraphicAtrMax";
import BhboxFilter from "./reports/bhbox/BhboxFilter";

const CADS = ["25", "50", "75", "100", "125", "150", "200", "250", "300"];
const CURRENT_YEAR = new Date().getFullYear();
const DATE = new Date();
const CURRENT_MONTH = DATE.getMonth() + 1
const CURRENT_DAY = DATE.getDay()
const currentAgriculturalYearDoesNotExist = DATE.getFullYear() && CURRENT_MONTH < 7;

const insertNextYear  = CURRENT_MONTH === 12 && CURRENT_DAY >= 1

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weather: {
        yearBegin: new Date().getFullYear().toString(),
        yearEnd: new Date().getFullYear().toString(),
      },
      weatherCompareYears: [
        (new Date().getFullYear() - 1).toString(),
        new Date().getFullYear().toString(),
      ],
      download: false,
      graphicActive: false,
      currentGraphic: 1,
      temperatureControl: "",
      weatherGraphic: false,
      filteredData: undefined,
      checkedData: [`${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`],
      yearsAuxiliarData: [],
      farmsCheckedData: [],
      numberOfFarms: 0,
      loading: true,
      bhboxType: false, //If false then it's a bhbox for one farm if true it's for multiple
      weatherGraphicTypeControl: false,
      weatherVariableControl: false,
      openCadDropdown: false,
      openVisualizationDropdown: false,
      bhboxExcDefAux: undefined,
      radioSelect: "0",
      rangeSelect: "0",
      variablesSelect: "0",
      beginSelect: "0",
      endSelect: "0",
      bhboxRange: "0",
      bhboxYear: "",
      bhBoxCheckedData: {
        EN: [],
        LN: [],
        N: [],
        all: [],
      },
      bhBoxSelectedEnoEvent: "all",
      lastYearWithData: new Date().getFullYear(),
      temperatureFilter: "all",
      startYear: '1980',
      endYear: `${CURRENT_YEAR}`,
      endYearAnomaly: `${CURRENT_YEAR - 1}`,
      startYearAgricultural: '80-81',
      endYearAgricultural: `${CURRENT_YEAR.toString().slice(2)}-${(CURRENT_YEAR + 1).toString().slice(2)}`,
      endYearAgriculturalAnomaly: `${(CURRENT_YEAR - 1).toString().slice(2)}-${CURRENT_YEAR.toString().slice(2)}`,
      selectStartYear: '1980',
      selectEndYear: `${CURRENT_YEAR}`,
      selectEndYearAnomaly: `${CURRENT_YEAR - 1}`,
      selectStartYearAgricultural: '80-81',
      selectEndYearAgricultural: CURRENT_MONTH < 7 
        ? `${(CURRENT_YEAR - 1).toString().slice(2)}-${(CURRENT_YEAR).toString().slice(2)}`
        : `${CURRENT_YEAR.toString().slice(2)}-${(CURRENT_YEAR + 1).toString().slice(2)}`,
      selectEndYearAgriculturalAnomaly: `${(CURRENT_YEAR - 1).toString().slice(2)}-${CURRENT_YEAR.toString().slice(2)}`,
      storedReport: undefined,
      climatologyCompare: true,
      showSchedule: false,
      scheduledDay: 7,
      scheduledPeriod: "morning",
      min_prec: "0",
      currentSelectedYearOFClimateFavorability: CURRENT_YEAR,
    };

    this.yearsInGraphic = 1;
    this.weatherPeriod = "daily";
    this.selectedYear = undefined;
    this.baseLastYear = undefined;
    this.baseFirstYear = undefined;
    this.agryfire = ["fmaac"];
  }
  
  //#region Lifecycle methods
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    //This may change when we have more than one product selected

    if (this.props.storedReport !== nextProps.storedReport) {
      this.setState({
        storedReport: nextProps.storedReport.report[0],
      });
    }

    if (nextProps.reportTypes === "favo" && nextProps?.report?.report) {
      this.setState({
        filteredData: nextProps.report.report,
      });
    }

    if (
      nextProps.reportTypes.includes("bhbox") === false &&
      nextProps.reportTypes.includes("extbh") === false &&
      nextProps.report !== undefined &&
      AGRYTOOLS.includes(nextProps.reportTypes[0]) === false &&
      this.agryfire.includes(nextProps.reportTypes[0]) === false &&
      nextProps.reportTypes !== "favo"
    ) {
      let data = [];
      data[0] = nextProps.report.report[0];

      if (this.props.storedReport) {
        data[1] = this.state.storedReport;
      } else {
        data[1] = undefined;
      }
      this.setState({
        weatherGraphic: true,
        filteredData: data,
      });

      if (
        nextProps.reportTypes.includes("temperatura") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "tmax" &&
            this.state.temperatureControl !== "tmin" &&
            this.state.temperatureControl !== "tmean"))
      ) {
        this.setState({
          temperatureControl: "tmean",
        });
      } else if (
        nextProps.reportTypes.includes("etretp") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "etretp" &&
            this.state.temperatureControl !== "etr" &&
            this.state.temperatureControl !== "etp"))
      ) {
        this.setState({
          temperatureControl: "etretp",
        });
      } else if (
        nextProps.reportTypes.includes("excdef") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "exc" &&
            this.state.temperatureControl !== "def"))
      ) {
        this.setState({
          temperatureControl: "def",
        });
      } else if (
        nextProps.reportTypes.includes("gd") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "5" &&
            this.state.temperatureControl !== "10" &&
            this.state.temperatureControl !== "15" &&
            this.state.temperatureControl !== "20"))
      ) {
        this.setState({
          temperatureControl: "10",
        });
      } else if (
        nextProps.reportTypes.includes("arm") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "25" &&
            this.state.temperatureControl !== "50" &&
            this.state.temperatureControl !== "75" &&
            this.state.temperatureControl !== "100" &&
            this.state.temperatureControl !== "125" &&
            this.state.temperatureControl !== "150"))
      ) {
        this.setState({
          temperatureControl: this.props.lastUsedCad.toString(),
        });
      } else if (
        nextProps.reportTypes.includes("atrmax") === true &&
        (this.state.temperatureControl === "" ||
          (this.state.temperatureControl !== "early" &&
            this.state.temperatureControl !== "medium" &&
            this.state.temperatureControl !== "late"))
      ) {
        this.setState({
          temperatureControl: "early",
        });
      }
    } else if (
      nextProps.reportTypes.includes("extbh") &&
      this.props.report === undefined
    ) {
      this.setState({
        graphicActive: true,
      });
    }

    if (
      nextProps.reportTypes.includes("etretp") === true &&
      nextProps.graphicType === "anomaly" &&
      this.state.temperatureControl === "etretp"
    ) {
      this.setState({
        temperatureControl: "etr",
      });
    }

    if (
      nextProps.selectedGridPointCoords["lat"] !==
        this.props.selectedGridPointCoords["lat"] ||
      nextProps.selectedGridPointCoords["lng"] !==
        this.props.selectedGridPointCoords["lng"]
    ) {
      this.setState({
        bhboxType: false,
      });
    }

    if (nextProps.correctTypesFlag) {
      this.setState({
        radioSelect: "0",
        rangeSelect: "0",
      });
      this.props.toggleCorrectTypesFlag();
    }

    if (
      !nextProps.allowXavierBhboxVariable &&
      this.props.allowXavierBhboxVariable
    ) {
      if (nextProps.report === undefined) {
        this.setState({
          bhboxType: true,
        });
      } else {
        this.setState({
          bhboxType: false,
        });
      }
    }

    if (
      ["period", "anomaly"].includes(nextProps.graphicType) &&
      ["cmp", "accumulate"].includes(this.props.graphicType)
    ) {
      let newYear =
        this.state.weatherCompareYears[
          this.state.weatherCompareYears.length - 1
        ];
      this.setState({
        weather: {
          yearBegin: newYear,
          yearEnd: this.state.weather.yearEnd,
        },
        beginSelect: newYear,
      });
    }

    if (
      this.props.mapYear !== this.state.weather.yearBegin &&
      ["period", "anomaly"].includes(nextProps.graphicType) &&
      nextProps.report !== undefined
    ) {
      this.setState({
        weather: {
          yearBegin: nextProps.mapYear,
          yearEnd: this.state.weather.yearEnd,
        },
        beginSelect: nextProps.mapYear,
      });
    } else if (
      this.props.visualizationType === 'agricultural' &&
      ["accumulate"].includes(nextProps.graphicType) &&
      nextProps.report !== undefined &&
      currentAgriculturalYearDoesNotExist &&
      CURRENT_YEAR.toString() === nextProps.mapYear
    ) {
        this.setState({
          weatherCompareYears: [(Number(nextProps.mapYear) - 2).toString(), (Number(nextProps.mapYear) - 1).toString()]
        });
    } else if (
      this.props.visualizationType === 'civil' &&
      !this.state.weatherCompareYears.includes(this.props.mapYear) &&
      ["cmp", "accumulate"].includes(nextProps.graphicType) &&
      nextProps.report !== undefined
    ) {
    
      this.setState({
        weatherCompareYears: [nextProps.mapYear],
      });
    }

    let compare = (a, b) => {
      if (parseInt(a.year, 10) < parseInt(b.year, 10)) {
        return -1;
      } else if (parseInt(a.year, 10) > parseInt(b.year, 10)) {
        return 1;
      }
      return 0;
    };
    let lastYear = undefined;

    if (
      nextProps.report !== undefined &&
      this.state.filteredData === undefined &&
      (nextProps.reportTypes.includes("bhbox") ||
        nextProps.reportTypes.includes("extbh") ||
        AGRYTOOLS.includes(nextProps.reportTypes[0])) &&
      nextProps.report.report !== undefined
    ) {
      nextProps.report["report"].map((row) => {
        row["bhbox"].sort(compare);
        return row["bhbox"];
      });

      nextProps.report["report"].map((row) => {
        let lastPosition = row["bhbox"].length;
        row["bhbox"].map((year, index) => {
          if (index === 0) {
            this.baseFirstYear = year["year"].toString();
          } else if (index + 1 === lastPosition) {
            //Test if bhbox in case of bhbox there's no data for entire January, meaning the year still haven't passed
            if (
              nextProps.reportTypes[0] === "bhbox" &&
              (year.data[0][2] === -1 || year.data[0][2].length < 11)
            ) {
              lastYear = (year["year"] - 1).toString();
            } else {
              lastYear = year["year"].toString();
            }
          }
          return lastYear;
        });
        return lastYear;
      });
      this.baseLastYear = lastYear;

      if (this.state.bhboxType) {
        let filteredData = [];
        let newData = { farm_id: -1, bhbox: [] };
        let newBhbox = [];
        nextProps.report["report"].forEach((row, index) => {
          newData = {
            farm_id: row["farm_id"],
            lat: row["lat"],
            lng: row["lng"],
            cad: row["cad"],
            bhbox: [],
          };
          newData["farm_id"] =
            row["farm_id"] !== undefined ? row["farm_id"] : -1;
          row["bhbox"].map((year) => {
            if (year["year"].toString() === this.baseLastYear) {
              newBhbox.push(year);
            }
            return newBhbox;
          });
          newData["bhbox"] = newBhbox;
          newBhbox = [];
          filteredData[index] = newData;
        });

        this.setState({
          filteredData: filteredData,
        });
      } else {
        if (
          this.props.reportTypes[0] === "extbh" &&
          this.selectedYear &&
          this.selectedYear !== ""
        ) {
          let yearsFilter = [];

          if (this.state.bhboxRange === "1") {
            yearsFilter[0] = parseInt(
              this.props.weatherMapDay.year - 1,
              10
            ).toString();
            yearsFilter[1] = this.props.weatherMapDay.year.toString();
          } else {
            yearsFilter[0] = this.props.weatherMapDay.year.toString();
          }

          if (this.selectedYear !== this.props.weatherMapDay.year) {
            this.selectedYear = this.props.weatherMapDay.year;
          }
          const filteredData = this.filterBHBoxData(
            nextProps.report["report"],
            yearsFilter
          );
          this.setState({
            filteredData,
            bhboxYear: this.selectedYear,
          });
        } else {
          this.setState({
            filteredData: nextProps.report["report"],
          });
        }
      }

      this.baseLastYear = lastYear;

      if (
        this.props.reportTypes[0] === "extbh" &&
        (this.state.bhboxYear !== "" || this.selectedYear !== "")
      ) {
        const filterYear =
          this.selectedYear !== "" ? this.selectedYear : this.state.bhboxYear;
        this.selectedYear = filterYear;
        this.props.setMapYear(filterYear);
      } else {
        this.selectedYear = lastYear;
        this.props.setMapYear(lastYear);
      }
    }
    //Agryfire box
    else if (
      nextProps.report !== undefined &&
      this.state.filteredData === undefined &&
      this.agryfire.includes(nextProps.reportTypes[0])
    ) {
      nextProps.report["report"].map((row) => {
        let lastPosition = row["agryfire"].length;
        row["agryfire"].map((year, index) => {
          if (index === 0) {
            this.baseFirstYear = year["year"].toString();
          } else if (index + 1 === lastPosition) {
            lastYear = year["year"].toString();
          }
          return lastYear;
        });
        return lastYear;
      });
      this.baseLastYear = lastYear;

      this.setState({
        filteredData: nextProps.report["report"],
      });

      this.baseLastYear = lastYear;
      this.selectedYear = lastYear;
      this.props.setMapYear(lastYear);
    } else if (
      nextProps.report === undefined &&
      this.state.filteredData !== undefined
    ) {
      this.setState({
        filteredData: undefined,
        loading: true,
        weatherGraphic: false,
        graphicActive: false,
      });
    }

    if (nextProps.mapYear && nextProps.mapYear !== this.selectedYear) {
      this.selectedYear = nextProps.mapYear;
      this.setState({
        weather: {
          yearBegin: nextProps.mapYear,
          yearEnd: nextProps.mapYear,
        },
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let filteredData = [];
    let newData = { farm_id: "", bhbox: [] };
    let newBhbox = [];
    if (nextProps.reportTypes.includes("fmaac") && this.state.bhboxType) {
      this.setState({
        bhboxType: false,
      });
    }

    //If a new point is selected when there is a bhbox report there is two possibilities
    //the first if statement is used when the users was looking at many farms (bhboxType == true) in this case we show the data for that farm only
    //in the else of that if the user was looking at one farm, but with some especific years selected so we show the data for the new farm on those years
    if (this.props.activeFarm !== nextProps.activeFarm) {
      if (this.props.report !== undefined) {
        if (this.state.bhboxType) {
          this.setState({
            farmsCheckedData: [],
            yearsAuxiliarData: [],
            filteredData: this.props.report["report"],
            bhboxType: false,
          });
          this.selectedYear = this.baseLastYear;
          this.props.setMapYear(this.selectedYear);
        } else if (
          this.state.checkedData.length === 0 &&
          this.state.yearsAuxiliarData.length !== 0 &&
          !this.state.graphicActive
        ) {
          this.props.report["report"].map((row) => {
            if (row["farm_id"] === nextProps.activeFarm) {
              newData["farm_id"] = row["farm_id"];
              row["bhbox"].map((year) => {
                if (
                  this.state.yearsAuxiliarData.includes(year["year"].toString())
                ) {
                  newBhbox.push(year);
                }
                return newBhbox;
              });
              newData["bhbox"] = newBhbox;
            }
            return newData;
          });
          filteredData.push(newData);
          this.setState({
            filteredData: filteredData,
          });
        }
      }
    }
  }

  changeGraphic = (graphic) => {
    this.setState({
      currentGraphic: graphic,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.reportTypes[0] !== prevProps.reportTypes[0]) {
      this.setState({ showSchedule: false });
    }
    //control the loading bar
    if (this.props.reportTypes[0] === "atrmax") {
      activePointChange(prevProps, this.props, "cana");
    }

    if (this.props.report === undefined && this.props.active) {
      this.props.showLoading();
    }
    if (this.state.filteredData !== undefined && this.state.loading) {
      if (this.props.visualizationType) this.props.hideLoading();
      this.setState({
        loading: false,
      });
    }

    if (
      prevProps.reportTypes[0] === "bhbox" &&
      this.props.reportTypes[0] !== "bhbox"
    ) {
      this.setState({
        yearsAuxiliarData: [],
        checkedData: [],
      });
    }

    if (this.props.scheduleStatus !== prevProps.scheduleStatus) {
      const status = Array.isArray(this.props.scheduleStatus)
        ? 'Email já cadastrado.'
        : this.props.scheduleStatus

      let toasterParams;
      switch (status) {
        case "Agendamentos cadastrados com sucesso ":
          toasterParams = {
            title: "Sucesso!",
            message: status + ".",
            status: true,
            autoClose: true,
          };
          showToaster(toasterParams);
          this.props.resetScheduleStatus();
          return;
        case "Limite de inclusão de emails externos já foi atingida":
          toasterParams = {
            title: "Erro!",
            message: status + ".",
            status: false,
            autoClose: true,
          };
          showToaster(toasterParams);
          this.props.resetScheduleStatus();
          return;
        case 'Email já cadastrado.':
          toasterParams = {
            title: "Erro!",
            message: this.props.scheduleStatus,
            status: true,
            autoClose: true,
          };
          showToaster(toasterParams);
          this.props.resetScheduleStatus();
          return;
        default:
          return;
      }
    }

    if (prevProps.graphicRange !== this.props.graphicRange) {
      this.handlePeriodFilter();
    }
  }
  //#endregion

  //#region Helper methods

  setLastDay(dec, month, year) {
    let months30 = ["04", "06", "09", "11"];
    let months31 = ["01", "03", "05", "07", "08", "10", "12"];
    let today = new Date();
    let day = today.getDate();
    let currentMonth = today.getMonth() + 1;
    let currentYear = today.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    if (currentMonth < 10) {
      currentMonth = "0" + currentMonth;
    }

    let currentYearCompare = currentYear === year ? true : false;
    let lastDay = undefined;

    if (dec === "1") {
      lastDay = "10";
    } else if (dec === "2") {
      lastDay = "20";
    } else {
      if (months30.includes(month)) {
        lastDay = "30";
      } else if (months31.includes(month)) {
        lastDay = "31";
      } else {
        if (leapYear(year)) {
          lastDay = "29";
        } else {
          lastDay = "28";
        }
      }
    }

    if (currentYearCompare) {
      return day;
    }

    return lastDay;
  }

  setDropdownLabels(type) {
    if (
      this.state.bhboxType ||
      this.state.graphicActive ||
      this.state.weatherGraphic
    ) {
      if (type === "farms") {
        return this.state.numberOfFarms.toString() + " Unidades";
      } else if (type === "years") {
        return "Em " + this.selectedYear;
      } else if (type === "graphic_years") {
        return this.selectedYear;
      } else if (type === "begin") {
        if (
          this.props.graphicType === "cmp" ||
          this.props.graphicType === "accumulate"
        ) {
          return "Anos";
        } else if (this.props.visualizationType === 'agricultural') {
          return `${this.state.weather.yearBegin}/${Number(this.state.weather.yearBegin) + 1}`;
        } else {
          return this.state.weather.yearBegin;
        }
      } else if (type === "end") {
        return this.state.weather.yearEnd;
      } else {
        return "Visualização";
      }
    } else {
      let farm = undefined;
      if (this.props.farms !== undefined) {
        farm = this.props.farms["farms"].filter(
          (farm) => farm.id === this.props.activeFarm
        )[0];
      }
      if (type === "farms" && this.props.activeFarm !== -1) {
        return "Unidade " + farm.initials;
      } else {
        return "Comparar Anos";
      }
    }
  }

  setHeaderLabel() {
    if (this.props.reportTypes === "favo") {
      return "Selecione o ano:";
    } else if (this.state.graphicActive) {
      return "ANO REFERÊNCIA:";
    } else if (this.state.weatherGraphic) {
      return "";
    } else if (this.state.bhboxType) {
      return "COMPARANDO:";
    } else if (!this.state.bhboxType && this.props.report !== undefined) {
      return "SELECIONADA:";
    } else {
      return "";
    }
  }

  renderMultipleYearsGraphic() {
    const { visualizationType } = this.props;

    if (this.state.graphicActive) {
      if (this.yearsInGraphic === 2) {
        return (
          <div className="row">
            <div className="col s6 years-of-graphic">
              {visualizationType === 'civil'
                ? (
                    <label>{this.selectedYear - 1}</label>
                  )
                : (
                    <label>{`${+this.selectedYear - 1}/${this.selectedYear}`}</label>
                  )}
            </div>
            <div className="col s6 years-of-graphic">
            {visualizationType === 'civil'
              ? (
                  <label>{this.selectedYear}</label>
                )
              : (
                  <label>{`${this.selectedYear}/${+this.selectedYear + 1}`}</label>
                )}
            </div>
          </div>
        );
      } else if (this.yearsInGraphic === 1) {
        return (
          <div className="col s12 years-of-graphic">
            {visualizationType === 'civil'
              ? (
                  <label>{this.selectedYear}</label>
                )
              : (
                  <label>{`${this.selectedYear}/${+this.selectedYear + 1}`}</label>
                )}
          </div>
        );
      }
    }
  }

  soilHelper(soilType) {
    if (soilType === "veryClayey") {
      return "Muito Argiloso";
    } else if (soilType === "clayey") {
      return "Argiloso";
    } else if (soilType === "mediumTexture") {
      return "Textura Média";
    } else if (soilType === "sandyAverageTexture") {
      return "Textura Média Arenosa";
    } else if (soilType === "sandy") {
      return "Arenoso";
    }
  }

  cadToSoil(cad, text) {
    if (text === 'Padrão do Ponto') {
      return undefined;
    }

    switch (cad) {
      case "25":
        return "sandy";
      case "50":
        return "sandyAverageTexture";
      case "75":
        return "mediumTexture";
      case "100":
        return "clayey";
      case "125":
        return "veryClayey";
      default:
        break;
    }
  }

  setVariablesLabel(control) {
    switch (control) {
      case "early":
        return "Precoce";
      case "medium":
        return "Média";
      case "late":
        return "Tardia";
      case "tmax":
        return "Temperatura Máxima";
      case "tmean":
        return "Temperatura Média";
      case "tmin":
        return "Temperatura Mínima";
      default:
        return control.toUpperCase();
    }
  }

  async exportAtrMaxData() {
    let farm = undefined;
      if (this.props.farms !== undefined) {
        farm = this.props.farms["farms"].filter(
          (farm) => farm.id === this.props.activeFarm
        )[0];
      }
    
    let farmAttrs = {
      name: farm.name,
      coords: farm.coords,
      farmId: farm.id,
      cad: farm.cad,
      initials: farm.initials,
      type: 'cmp',
      range: 'daily',
      data_files: '',
      initial_date: "1980",
      final_date: (new Date().getFullYear() + 1).toString(),
      cultivate_type: 'early',
      tb: this.props.tBasal,
      visualization_type: this.props.visualizationType,
      min_prec: 0,
      spreadsheet_return : true
    };

    const { data } = await ReportsApi.spreadsheetGenerator(this.props.reportTypes, farmAttrs);
    
    if (!data) {
      this.setState({
        download: false,
      });
    }
  };
    

  getDataToExportHeaders() {
    if (this.props.reportTypes[0] === "temperatura") {
      return [
        { label: "Data", key: "date" },
        { label: "Temperatura Máxima", key: "tmax" },
        { label: "Temperatura Média", key: "tmean" },
        { label: "Temperatura Mínima", key: "tmin" },
      ];
    } else if (this.props.reportTypes[0] === "etp") {
      return [
        { label: "Data", key: "date" },
        { label: "Evapotranspiração Potencial (mm)", key: "etp" },
      ];
    } else if (this.props.reportTypes[0] === "u2") {
      return [
        { label: "Data", key: "date" },
        { label: "Vento a 2m (m/s)", key: "u2" },
      ];
    } else if (this.props.reportTypes[0] === "ur") {
      return [
        { label: "Data", key: "date" },
        { label: "Umidade Relativa do Ar (%)", key: "ur" },
      ];
    } else if (this.props.reportTypes[0] === "gd") {
      return [
        { label: "Data", key: "date" },
        {
          label: "Graus-dia (°C/dia) - Tb: " + this.props.tBasal + " °C",
          key: "gd",
        },
      ];
    } else if (this.props.reportTypes[0] === "rs") {
      return [
        { label: "Data", key: "date" },
        { label: "Radiação Solar (MJ/m²/d)", key: "rs" },
      ];
    } else if (this.props.reportTypes[0] === "prec") {
      return [
        { label: "Data", key: "date" },
        { label: "Precipitação (mm)", key: "prec" },
      ];
    } else if (this.props.reportTypes[0] === "frost_risk") {
      return [
        { label: "Data", key: "date" },
        { label: "dia(s) com Tmin <= 2º", key: "frost_risk" },
      ];
    } else if (this.props.reportTypes[0] === "heat_wave") {
      return [
        { label: "Data", key: "date" },
        { label: "dia(s) com Tmax >= 32º", key: "heat_wave" },
      ];
    } else if (this.props.reportTypes[0] === "extbh") {
      return [
        { label: "Data", key: "date" },
        { label: "Balanço Hídrico (mm)", key: "data" },
      ];
    } else if (this.props.reportTypes[0] === "bhbox") {
      return [
        { label: "Data", key: "date" },
        { label: "Armazenamento de Água", key: "data" },
      ];
    } else if (this.props.reportTypes[0] === "etretp") {
      return [
        { label: "Data", key: "date" },
        { label: "ETR", key: "etr" },
        { label: "ETP", key: "etp" },
      ];
    }
  }

  getDataToExport() {
    if (!this.props.report.report) {
      return;
    }
    let dataArray = [];

    if (this.props.reportTypes[0] === "temperatura") {
      const { temperatura, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        temperatura.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            tmax: year.data[0].tmax.toFixed(1),
            tmean: year.data[0].tmean.toFixed(1),
            tmin: year.data[0].tmin.toFixed(1),
          });
        });
      } else {
        const getTemperatureData = (item) => ({
          date: `${item.name}/${item.year}`,
          tmax: item.tmax.toFixed(1),
          tmean: item.tmean.toFixed(1),
          tmin: item.tmin.toFixed(1),
        });

        const getTemperaturePredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          tmax: item.p_tmax.toFixed(1),
          tmean: item.p_tmean.toFixed(1),
          tmin: item.p_tmin.toFixed(1),
        });

        temperatura[temperatura.length - 3].data.forEach((item) =>
          dataArray.push(getTemperatureData(item))
        );
        temperatura[temperatura.length - 2].data.forEach((item) =>
          dataArray.push(getTemperatureData(item))
        );
        temperatura[temperatura.length - 1].data.forEach((item) => {
          if (item && item.tmax && item.tmean && item.tmin) {
            dataArray.push(getTemperatureData(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_tmax !== undefined) {
              dataArray.push(getTemperaturePredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "etp") {
      const { etp, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        etp.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            etp: year.data[0].etp.toFixed(1),
          });
        });
      } else {
        const getEtpData = (item) => ({
          date: `${item.name}/${item.year}`,
          etp: item.etp.toFixed(1),
        });

        const getEtpPredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          etp: item.p_etp.toFixed(1),
        });

        etp[etp.length - 3].data.forEach((item) =>
          dataArray.push(getEtpData(item))
        );
        etp[etp.length - 2].data.forEach((item) =>
          dataArray.push(getEtpData(item))
        );
        etp[etp.length - 1].data.forEach((item) => {
          if (item && item.etp) {
            dataArray.push(getEtpData(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_etp !== undefined) {
              dataArray.push(getEtpPredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "u2") {
      const { u2, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        u2.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            u2: year.data[0].u2.toFixed(1),
          });
        });
      } else {
        const getU2Data = (item) => ({
          date: `${item.name}/${item.year}`,
          u2: item.u2.toFixed(1),
        });

        const getU2PredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          u2: item.p_u2.toFixed(1),
        });

        u2[u2.length - 3].data.forEach((item) =>
          dataArray.push(getU2Data(item))
        );
        u2[u2.length - 2].data.forEach((item) =>
          dataArray.push(getU2Data(item))
        );
        u2[u2.length - 1].data.forEach((item) => {
          if (item && item.u2) {
            dataArray.push(getU2Data(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_u2 !== undefined) {
              dataArray.push(getU2PredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "ur") {
      const { ur, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        ur.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            ur: year.data[0].ur.toFixed(1),
          });
        });
      } else {
        const getUrData = (item) => ({
          date: `${item.name}/${item.year}`,
          ur: item.ur.toFixed(1),
        });

        const getUrPredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          ur: item.p_ur.toFixed(1),
        });

        ur[ur.length - 3].data.forEach((item) =>
          dataArray.push(getUrData(item))
        );
        ur[ur.length - 2].data.forEach((item) =>
          dataArray.push(getUrData(item))
        );
        ur[ur.length - 1].data.forEach((item) => {
          if (item && item.ur) {
            dataArray.push(getUrData(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_ur !== undefined) {
              dataArray.push(getUrPredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "gd") {
      const { gd, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        gd.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            gd: year.data[0].gd.toFixed(1),
          });
        });
      } else {
        const getGdData = (item) => ({
          date: `${item.name}/${item.year}`,
          gd: item.gd.toFixed(1),
        });

        const getGdPredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          gd: item.p_gd.toFixed(1),
        });

        gd[gd.length - 3].data.forEach((item) =>
          dataArray.push(getGdData(item))
        );
        gd[gd.length - 2].data.forEach((item) =>
          dataArray.push(getGdData(item))
        );
        gd[gd.length - 1].data.forEach((item) => {
          if (item && item.gd) {
            dataArray.push(getGdData(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_gd !== undefined) {
              dataArray.push(getGdPredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "rs") {
      const { rs, prediction } = this.props.report.report[0];
      if (this.props.graphicRange === "annualy") {
        rs.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            rs: year.data[0].rs.toFixed(1),
          });
        });
      } else {
        const getRsData = (item) => ({
          date: `${item.name}/${item.year}`,
          rs: item.rs.toFixed(1),
        });

        const getRsPredictionData = (item) => ({
          date: `${item.name}/${item.year}`,
          rs: item.p_rs.toFixed(1),
        });

        rs[rs.length - 3].data.forEach((item) =>
          dataArray.push(getRsData(item))
        );
        rs[rs.length - 2].data.forEach((item) =>
          dataArray.push(getRsData(item))
        );
        rs[rs.length - 1].data.forEach((item) => {
          if (item && item.rs) {
            dataArray.push(getRsData(item));
          }
        });

        if (prediction.length > 0) {
          prediction[0].data.forEach((item) => {
            if (item && item.p_rs !== undefined) {
              dataArray.push(getRsPredictionData(item));
            }
          });
        }
      }
    } else if (this.props.reportTypes[0] === "prec") {
      const { prec, prediction } = this.props.report.report[0];

      if (this.props.graphicRange === "annualy") {
        prec.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            prec: year.data[0].prec.toFixed(1),
          });
        });
      } else {
        const getPrecData = (item, year) => ({
          date: `${item.name}/${year}`,
          prec: item.prec.toFixed(1),
        });

        //The if for the last year exists because some charts can return prec undefined (monthly chart for example)
        prec[prec.length - 3].data.forEach((item) =>
          dataArray.push(getPrecData(item, item.year))
        );
        prec[prec.length - 2].data.forEach((item) =>
          dataArray.push(getPrecData(item, item.year))
        );
        prec[prec.length - 1].data.forEach((item) => {
          if (item && item.prec !== undefined) {
            dataArray.push(getPrecData(item, item.year));
          }
        });
        if (prediction.length > 0) {
          prediction.forEach((year) => {
            year.data.forEach((item) => {
              if (item && item.p_prec !== undefined) {
                dataArray.push({
                  date: `${item.name}/${item.year}`,
                  prec: item.p_prec.toFixed(1),
                });
              }
            });
          });
        }
      }
    } else if (this.props.reportTypes[0] === "frost_risk") {
      const { frost_risk, prediction } = this.props.report.report[0];

      if (this.props.graphicRange === "annualy") {
        frost_risk.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            frost_risk: year.data[0].frost_risk.toFixed(1),
          });
        });
      } else {
        const getFrostRiskData = (item, year) => ({
          date: `${item.name}/${year}`,
          frost_risk: item.frost_risk.toFixed(1),
        });

        //The if for the last year exists because some charts can return prec undefined (monthly chart for example)
        frost_risk[frost_risk.length - 3].data.forEach((item) =>
          dataArray.push(getFrostRiskData(item, item.year))
        );
        frost_risk[frost_risk.length - 2].data.forEach((item) =>
          dataArray.push(getFrostRiskData(item, item.year))
        );
        frost_risk[frost_risk.length - 1].data.forEach((item) => {
          if (item && item.frost_risk !== undefined) {
            dataArray.push(getFrostRiskData(item, item.year));
          }
        });
        if (prediction.length > 0) {
          prediction.forEach((year) => {
            year.data.forEach((item) => {
              if (item && item.p_frost_risk !== undefined) {
                dataArray.push({
                  date: `${item.name}/${item.year}`,
                  frost_risk: item.p_frost_risk.toFixed(1),
                });
              }
            });
          });
        }
      }
    } else if (this.props.reportTypes[0] === "heat_wave") {
      const { heat_wave, prediction } = this.props.report.report[0];

      if (this.props.graphicRange === "annualy") {
        heat_wave.forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            heat_wave: year.data[0].heat_wave.toFixed(1),
          });
        });
      } else {
        const getHeatWaveData = (item, year) => ({
          date: `${item.name}/${year}`,
          heat_wave: item.heat_wave.toFixed(1),
        });

        //The if for the last year exists because some charts can return prec undefined (monthly chart for example)
        heat_wave[heat_wave.length - 3].data.forEach((item) =>
          dataArray.push(getHeatWaveData(item, item.year))
        );
        heat_wave[heat_wave.length - 2].data.forEach((item) =>
          dataArray.push(getHeatWaveData(item, item.year))
        );
        heat_wave[heat_wave.length - 1].data.forEach((item) => {
          if (item && item.heat_wave !== undefined) {
            dataArray.push(getHeatWaveData(item, item.year));
          }
        });
        if (prediction.length > 0) {
          prediction.forEach((year) => {
            year.data.forEach((item) => {
              if (item && item.p_heat_wave !== undefined) {
                dataArray.push({
                  date: `${item.name}/${item.year}`,
                  heat_wave: item.p_heat_wave.toFixed(1),
                });
              }
            });
          });
        }
      }
    } else if (this.props.reportTypes[0] === "extbh") {
      const { bhbox } = this.props.report.report[0];

      const processExtBhData = (item, dataArray) => {
        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);

        for (let monthIdx = 0; monthIdx < item.hydric_data.length; monthIdx++) {
          const month = item.hydric_data[monthIdx];
          const formattedMonth =
            (monthIdx + 1).toString().length === 1
              ? `0${monthIdx + 1}`
              : monthIdx + 1;
          let date = `${formattedMonth}/${item.year}`;
          let day = 1;

          for (let weekIdx = 0; weekIdx < month.length; weekIdx++) {
            for (let dayIdx = 0; dayIdx < month[weekIdx].length; dayIdx++) {
              //Validation not to include prediction data on the report
              //As currently we cannot differ observed data from prediction data
              const formattedDay =
                day.toString().length === 1 ? `0${day}` : day;

              dataArray.push({
                date: `${formattedDay}/${date}`,
                data: month[weekIdx][dayIdx],
              });

              day++;
            }
          }
        }
      };

      processExtBhData(bhbox[bhbox.length - 3], dataArray);
      processExtBhData(bhbox[bhbox.length - 2], dataArray);
      processExtBhData(bhbox[bhbox.length - 1], dataArray);
    } else if (this.props.reportTypes[0] === "bhbox") {
      const { bhbox } = this.props.report.report[0];

      const processBhboxData = (item, dataArray) => {
        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);

        for (let monthIdx = 0; monthIdx < item.data.length; monthIdx++) {
          const month = item.data[monthIdx];
          const formattedMonth =
            (monthIdx + 1).toString().length === 1
              ? `0${monthIdx + 1}`
              : monthIdx + 1;
          let date = `${formattedMonth}/${item.year}`;
          let day = 1;

          for (let weekIdx = 0; weekIdx < month.length; weekIdx++) {
            for (let dayIdx = 0; dayIdx < month[weekIdx].length; dayIdx++) {
              //Validation not to include prediction data on the report
              //As currently we cannot differ observed data from prediction data
              const formattedDay =
                day.toString().length === 1 ? `0${day}` : day;

              dataArray.push({
                date: `${formattedDay}/${date}`,
                data: month[weekIdx][dayIdx],
              });

              day++;
            }
          }
        }
      };

      processBhboxData(bhbox[bhbox.length - 3], dataArray);
      processBhboxData(bhbox[bhbox.length - 2], dataArray);
      processBhboxData(bhbox[bhbox.length - 1], dataArray);
    } else if (this.props.reportTypes[0] === "etretp") {
      if (this.props.graphicRange === "annualy") {
        const { bhbox } = this.props.report.report[0];

        bhbox[0].forEach((year) => {
          dataArray.push({
            date: year.data[0].name,
            etr: year.data[0].ETR.toFixed(2),
            etp: year.data[0].ETP.toFixed(2),
          });
        });
      } else {
        const { bhbox } = this.props.report.report[0];

        const processEtrEtpData = (item, dataArray) => {
          const yesterdayDate = new Date();
          yesterdayDate.setDate(yesterdayDate.getDate() - 1);

          item.data.forEach((day) => {
            //Validation not to include prediction data on the report
            //As currently we cannot differ observed data from prediction data
            if (day.ETR && day.ETP) {
              dataArray.push({
                date: `${day.name}/${day.year}`,
                etr: day.ETR.toFixed(2),
                etp: day.ETP.toFixed(2),
              });
            }
          }, dataArray);
        };

        processEtrEtpData(bhbox[bhbox.length - 3], dataArray);
        processEtrEtpData(bhbox[bhbox.length - 2], dataArray);
        processEtrEtpData(bhbox[bhbox.length - 1], dataArray);
      }
    }

    return dataArray;
  }

  showExportDataButton() {
    return (
      this.props.report &&
      (([
        "frost_risk",
        "heat_wave",
        "temperatura",
        "prec",
        "etp",
        "u2",
        "ur",
        "gd",
        "rs",
      ].includes(this.props.reportTypes[0]) &&
        this.props.graphicType === "period" &&
        this.props.mapYear >= new Date().getFullYear().toString()) ||
        this.props.reportTypes[0] === "bhbox" ||
        this.props.reportTypes[0] === "extbh" ||
        (this.props.reportTypes[0] === "etretp" &&
          this.props.graphicType === "period"))
    );
  }

  renderDropdowns() {
    if (this.props.report !== undefined) {
      //dropdown header for ext-bh
      if (this.state.graphicActive) {
        let length = this.props.report.report[0].bhbox.length;
        return (
          <Fragment>
            <BhboxDropdown
              leftIcon="event"
              label={this.setDropdownLabels("graphic_years")}
              active={this.props.farmsDropdownActive}
              onClick={this.props.toggleFarmsDropdown}
              data={this.props.report.report[0]}
              checkedData={this.state.checkedData}
              onChange={this.handleCheckData}
              keysToFilters={["year"]}
              onSendClick={this.handleSendClick}
              farms={false}
              graphicControl={false}
              bhboxType={this.state.bhboxType}
              selectedYear={this.selectedYear}
              graphicActive={this.state.graphicActive}
              firstYear={this.baseFirstYear}
              optionsType={"years"}
              getEnos={this.props.getEnos}
              years={this.props.enos}
              radioSelect={this.state.bhboxYear}
              rightNeighbor={true}
              clear={this.clearCheckedData}
              reportType={
                this.props.reportTypes[0] ? this.props.reportTypes[0] : null
              }
              visualizationType={this.props.visualizationType}
              lastYearWithData={
                this.props.report.report[0].bhbox[length - 1].year
              }
            />
            <BhboxDropdown
              leftIcon="history"
              label={this.setDropdownLabels("graphic_period")}
              active={this.props.yearsDropdownActive}
              onClick={this.props.toggleYearsDropdown}
              data={this.props.report.report[0]}
              checkedData={this.state.checkedData}
              onChange={this.handleCheckData}
              keysToFilters={["year"]}
              onSendClick={this.handleSendClick}
              farms={false}
              graphicControl={true}
              bhboxType={this.state.bhboxType}
              selectedYear={this.selectedYear}
              graphicActive={this.state.graphicActive}
              firstYear={this.baseFirstYear}
              optionsType={"periods"}
              radioSelect={this.state.bhboxRange}
              rightNeighbor={true}
              reportType={
                this.props.reportTypes[0] ? this.props.reportTypes[0] : null
              }
              visualizationType={this.props.visualizationType}
            />
            <WeatherDropdown
              leftIcon="water_drop"
              label={this.props.lastUsedCad + " mm"}
              active={this.state.weatherVariableControl}
              onClick={this.toggleWeatherVariableDropdown}
              data={this.props.report.report}
              checkedData={this.state.checkedData}
              onChange={this.handleCheckData}
              onSendClick={this.weatherHandleSendClick}
              selectedYear={this.selectedYear}
              graphicControl={false}
              yearBegin={this.state.weather.yearBegin}
              optionsType={"variables"}
              weatherType={this.props.reportTypes[0]}
              weatherGraphicType={this.props.graphicType}
              fourthDrop={false}
              radioSelect={this.state.variablesSelect}
              rightNeighbor={true}
              isCadDropdown={true}
            />
            <VisualizationDropdown
              visualizationType={this.props.visualizationType}
              handleVisualizationType={this.visualizationSendClick}
              selectedFarm={this.state.selectedFarm}
              selectedYear={this.selectedYear}
              graphicType={this.props.reportTypes[0]}
            />
          </Fragment>
        );
        //dropdown header for weather graphics
      } else if (this.state.weatherGraphic) {
        const { graphicType, graphicRange, visualizationType } = this.props

        return (
          <Fragment>
            {this.props.reportTypes[0] !== "excdef" &&
            this.props.reportTypes[0] !== "arm" &&
            this.props.reportTypes[0] !== "atrmax" ? (
              <WeatherDropdown
                leftIcon="show_chart"
                label={"Gráfico"}
                active={this.state.weatherGraphicTypeControl}
                onClick={this.toggleWeatherGraphicTypeDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={false}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"radio"}
                weatherType={this.props.reportTypes[0]}
                radioSelect={this.props.graphicType || this.state.radioSelect}
                rangeSelect={this.props.graphicRange || this.state.rangeSelect}
                rightNeighbor={true}
              />
            ) : null}
            {this.props.graphicRange === "annualy" ? null : (
              <WeatherDropdown
                leftIcon="event"
                label={this.setDropdownLabels("begin")}
                active={this.props.farmsDropdownActive}
                onClick={this.props.toggleFarmsDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={true}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"years"}
                weatherType={this.props.reportTypes[0]}
                weatherGraphic={this.props.graphicType}
                weatherGraphicType={this.props.graphicType}
                years={(insertNextYear && visualizationType === 'civil' ) && (graphicType === 'period' && graphicRange === 'daily')
                ? [...this.props.enos, CURRENT_YEAR + 1]
                : this.props.enos
              }
                visualizationType={this.props.visualizationType}
                getEnos={this.props.getEnos}
                radioSelect={this.props.mapYear || this.state.beginSelect}
                rightNeighbor={true}
                clear={this.clearCheckedData}
              />
            )}
            {
              this.props.reportTypes[0] === 'heat_wave' && (
                <WeatherDropdown
                  leftIcon="sunny"
                  label={"Onda de Calor"}
                  active={this.state.weatherVariableControl}
                  onClick={this.toggleWeatherVariableDropdown}
                  data={this.props.report.report}
                  checkedData={this.state.checkedData}
                  onChange={this.handleCheckData}
                  onSendClick={this.weatherHandleSendClick}
                  selectedYear={this.selectedYear}
                  graphicControl={false}
                  yearBegin={this.state.weather.yearBegin}
                  optionsType={"variables"}
                  weatherType={this.props.reportTypes[0]}
                  weatherGraphicType={this.props.graphicType}
                  fourthDrop={true}
                  radioSelect={this.state.variablesSelect}
                  rightNeighbor={true}
                />
              )
            }
            {(this.props.reportTypes[0] === "temperatura" &&
              (this.props.graphicType === "cmp" ||
                this.props.graphicType === "anomaly")) ||
            this.props.reportTypes[0] === "excdef" ||
            this.props.reportTypes[0] === "etretp" ||
            this.props.reportTypes[0] === "gd" ||
            this.props.reportTypes[0] === "arm" ||
            this.props.reportTypes[0] === "atrmax" ? (
              <WeatherDropdown
                leftIcon="event"
                label={
                  this.state.temperatureControl === "etretp"
                    ? "ETR/ETP"
                    : this.props.reportTypes[0] === "gd"
                    ? this.state.temperatureControl.toUpperCase() + " °C"
                    : this.props.reportTypes[0] === "arm"
                    ? this.state.temperatureControl.toUpperCase() + " mm"
                    : this.setVariablesLabel(this.state.temperatureControl)
                }
                active={this.state.weatherVariableControl}
                onClick={this.toggleWeatherVariableDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={false}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"variables"}
                weatherType={this.props.reportTypes[0]}
                weatherGraphicType={this.props.graphicType}
                fourthDrop={true}
                radioSelect={this.state.variablesSelect}
                rightNeighbor={true}
              />
            ) : null}
            {
              this.props.reportTypes[0] === 'atrmax' && (
                <WeatherDropdown
                  leftIcon="event"
                  label={
                    this.props.visualizationType === "civil"
                      ? "Civil"
                      : "Agrícola"
                  }
                  active={this.state.openVisualizationDropdown}
                  onClick={this.toggleVisualizationDropdown}
                  data={this.props.report.report}
                  checkedData={this.state.checkedData}
                  onChange={this.handleCheckData}
                  onSendClick={this.visualizationSendClick}
                  selectedYear={this.selectedYear}
                  graphicControl={false}
                  yearBegin={this.state.weather.yearBegin}
                  optionsType={"variables"}
                  weatherType={this.props.reportTypes[0]}
                  weatherGraphicType={this.props.graphicType}
                  fourthDrop={false}
                  radioSelect={this.props.visualizationType}
                  rightNeighbor={true}
                  visualizationDropdown={true}
                />
              )
            }
            {this.props.reportTypes[0] === "rainy_days" && (
              <WeatherDropdown
                leftIcon="event"
                label={"Volume de Precipitação"}
                active={this.state.weatherVariableControl}
                onClick={this.toggleWeatherVariableDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={false}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"variables"}
                weatherType={this.props.reportTypes[0]}
                weatherGraphicType={this.props.graphicType}
                fourthDrop={true}
                radioSelect={this.state.variablesSelect}
                rightNeighbor={true}
              />
            )}
            {this.props.reportTypes[0] === "excdef" ||
            (this.props.reportTypes[0] === "etretp" &&
              this.state.variablesSelect !== "etp") ? (
              <WeatherDropdown
                leftIcon="water_drop"
                label={this.props.lastUsedCad + " mm"}
                active={this.state.openCadDropdown}
                onClick={this.toggleCadDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={false}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"variables"}
                weatherType={this.props.reportTypes[0]}
                weatherGraphicType={this.props.graphicType}
                fourthDrop={false}
                radioSelect={this.state.variablesSelect}
                rightNeighbor={true}
                isCadDropdown={true}
              />
            ) : null}
            {this.props.reportTypes[0] === "temperatura" &&
              this.props.graphicType === "climatology" && (
                <TemperatureDropdown
                  leftIcon="event"
                  active={this.props.soilDropdownActive}
                  onClick={this.props.toggleSoilDropdown}
                  onTemperatureSelect={this.handleTemperatureSendClick}
                  rightNeighbor={true}
                  radioSelect={this.state.temperatureFilter}
                />
                )}
              {['temperatura', 'prec', 'excdef', 'rs', 'gd', 'u2'].includes(this.props.reportTypes[0]) && (
                <VisualizationDropdown
                  visualizationType={this.props.visualizationType}
                  handleVisualizationType={this.visualizationSendClick}
                  selectedFarm={this.state.selectedFarm}
                  selectedYear={this.selectedYear}
                  graphicType={this.props.graphicType}
                />
              )}
              {
                ['temperatura', 'prec', 'atrmax', 'excdef', 'rs', 'gd', 'u2'].includes(this.props.reportTypes[0]) && (
                  this.props.graphicRange === 'daily'
                    ? (
                      <div style={{ display: 'flex' }}>
                      <h6>{'Período: '}</h6>
                      <TextField
                        style={{ margin: "0 5px" }}
                        id="fromPeriod"
                        type="search"
                        onChange={this.onDateChange}
                        value={this.props.filterParams.fromPeriod}
                      />
                      <h6>a </h6>
                      <TextField
                        style={{ margin: "0 5px" }}
                        id="toPeriod"
                        type="search"
                        onChange={this.onDateChange}
                        value={this.props.filterParams.toPeriod}
                      />
                      <Button
                        sx={{
                          margin: '5px',
                          height: '25px',
                          color: 'white',
                          background: '#6bb324',
                          textTransform: 'none',
                          cursor: 'pointer',
                          '&:hover': {
                            background: '#6bb324',
                          },
                        }}
                        onClick={
                          this.props.reportTypes[0] !== 'atrmax'
                            ? this.handlePeriodFilter
                            : this.onFilterClick
                        }
                      >
                        Filtrar
                      </Button>
                    </div>
                  )
                  : (
                    <>
                        <h6>{'Período: '}</h6>
                      <MonthSelect
                        type={this.props.graphicRange !== 'annualy'
                          ? 'fromPeriod'
                          : this.props.visualizationType === 'civil'
                          ? 'selectStartYear'
                          : 'selectStartYearAgricultural'
                        }
                        onDateChange={this.onDecendioChange}
                        value={this.props.graphicRange !== 'annualy'
                          ? this.props.filterParams.fromPeriod
                          : this.props.visualizationType === 'civil'
                          ? this.state.selectStartYear
                          : this.state.selectStartYearAgricultural
                        }
                        graphicRange={this.props.graphicRange}
                        visualizationType={this.props.visualizationType}
                        graphicType={this.props.graphicType}
                        currentMonth={CURRENT_MONTH}
                        startDate={1980}

                      />
                      <h6>a</h6>
                      <MonthSelect
                        type={this.props.graphicRange !== 'annualy'
                          ? 'toPeriod'
                          : this.props.visualizationType === 'civil'
                          ?  this.props.graphicType === 'anomaly' ? 'selectEndYearAnomaly' : 'selectEndYear'
                          : this.props.graphicType === 'anomaly' ? 'selectEndYearAgriculturalAnomaly' : 'selectEndYearAgricultural'
                        }
                        onDateChange={this.onDecendioChange}
                        value={this.props.graphicRange !== 'annualy'
                          ? this.props.filterParams.toPeriod
                          : this.props.visualizationType === 'civil'
                          ? this.props.graphicType === 'anomaly' 
                          ? this.state.selectEndYearAnomaly : this.state.selectEndYear
                          : this.props.graphicType === 'anomaly'
                          ? this.state.selectEndYearAgriculturalAnomaly : this.state.selectEndYearAgricultural
                        }
                        graphicRange={this.props.graphicRange}
                        visualizationType={this.props.visualizationType}
                        graphicType={this.props.graphicType}
                        currentMonth={CURRENT_MONTH}
                        startDate={Number(this.state.startYear)}
                      />
                      <Button
                        sx={{
                          margin: '5px',
                          height: '25px',
                          color: 'white',
                          background: '#6bb324',
                          textTransform: 'none',
                          cursor: 'pointer',
                          '&:hover': {
                            background: '#6bb324',
                          },
                        }}
                        onClick={this.props.graphicRange !== 'annualy' ? this.handlePeriodFilter : this.handleYearFilter}
                      >
                        Filtrar
                      </Button>
                    </>
                  )
                )
              }
          </Fragment>
        );
      } else if (this.agryfire.includes(this.props.reportTypes[0])) {
        return (
          <Fragment>
            <BhboxDropdown
              leftIcon="date_range"
              label={this.setDropdownLabels("years")}
              active={this.props.yearsDropdownActive}
              onClick={this.props.toggleYearsDropdown}
              data={this.props.report.report[0]}
              checkedData={this.state.checkedData}
              onChange={this.handleCheckData}
              keysToFilters={["year"]}
              onSendClick={this.handleSendClick}
              farms={false}
              bhboxType={false}
              selectedYear={this.selectedYear}
              graphicActive={this.state.graphicActive}
              optionsType={"years"}
              getEnos={this.props.getEnos}
              years={this.props.enos}
              agryIndex={false}
              rightNeighbor={true}
              onEnoEventChange={(event) =>
                this.setState({ bhBoxSelectedEnoEvent: event })
              }
              clear={this.clearCheckedData}
              reportType={
                this.props.reportTypes[0] ? this.props.reportTypes[0] : null
              }
              visualizationType={this.props.visualizationType}
            />
          </Fragment>
        );
      } else if (this.props.reportTypes === "favo") {
        const auxArrayWithAllYearsForIndexSelect = [];
        for (let i = 1980; i <= new Date().getFullYear(); i++) {
          auxArrayWithAllYearsForIndexSelect.push(i);
        }

        return (
          <Select
            value={this.state.currentSelectedYearOFClimateFavorability}
            onChange={(e) =>
              this.setState(
                {
                  currentSelectedYearOFClimateFavorability: e.target.value,
                },
                () => {
                  const farm = this.props.farms["farms"].filter(
                    (farm) => farm.id === this.props.activeFarm
                  )[0];
                  let farmAttrs = {
                    coords: farm.coords,
                    farmId: this.props.activeFarm,
                    cad: this.props.cad,
                    year: this.state.currentSelectedYearOFClimateFavorability,
                  };

                  this.props.toggleReportUndefined();

                  this.props.generatingMapData();
                  this.props.generateReport("favo", farmAttrs);
                }
              )
            }
          >
            {auxArrayWithAllYearsForIndexSelect.map((year) => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </Select>
        );
      } else {
        //dropdown header for bhbox
        let length = this.props.report.report[0].bhbox.length;
        return (
          <Fragment>
            {this.props.user["type"] === "bank" ? (
              <button
                className="btn-flat btn-flat-compare btn-drop button-compare-bank-user"
                onClick={this.handleXavierBhbox}
              >
                {"COMPARAR PONTOS"}
              </button>
            ) : this.props.activeFarm !== -1 ? (
              <BhboxDropdown
                leftIcon="group_work"
                label={this.setDropdownLabels("farms")}
                active={this.props.farmsDropdownActive}
                onClick={this.props.toggleFarmsDropdown}
                data={
                  this.props.farms !== undefined
                    ? this.props.farms["farms"]
                    : this.props.xavierGrid["xavier_points"]
                }
                checkedData={this.state.checkedData}
                farmsCheckedData={this.state.farmsCheckedData}
                onChange={this.handleCheckData}
                keysToFilters={["name"]}
                onSendClick={this.handleSendClick}
                farms={true}
                bhboxType={this.state.bhboxType}
                selectedYear={this.selectedYear}
                graphicActive={this.state.graphicActive}
                optionsType={"farms"}
                userType={this.props.user["type"]}
                rightNeighbor={true}
                clear={this.clearFarmsCheckedData}
                reportType={
                  this.props.reportTypes[0] ? this.props.reportTypes[0] : null
                }
              />
            ) : null}
            <BhboxDropdown
              leftIcon="date_range"
              label={this.setDropdownLabels("years")}
              active={this.props.yearsDropdownActive}
              onClick={this.props.toggleYearsDropdown}
              data={this.props.report.report[0]}
              checkedData={this.state.checkedData}
              onChange={this.handleCheckData}
              keysToFilters={["year"]}
              onSendClick={this.handleSendClick}
              farms={false}
              bhboxType={this.state.bhboxType}
              selectedYear={this.selectedYear}
              graphicActive={this.state.graphicActive}
              optionsType={"years"}
              getEnos={this.props.getEnos}
              years={insertNextYear && this.props.visualizationType === 'civil'
                ? [...this.props.enos, CURRENT_YEAR + 1]
                : this.props.enos
              }
              agryIndex={
                AGRYTOOLS.includes(this.props.reportTypes[0]) ? true : false
              }
              rightNeighbor={true}
              onEnoEventChange={(event) =>
                this.setState({ bhBoxSelectedEnoEvent: event })
              }
              clear={this.clearCheckedData}
              reportType={
                this.props.reportTypes[0] ? this.props.reportTypes[0] : null
              }
              visualizationType={this.props.visualizationType}
              lastYearWithData={
                this.props.report.report[0].bhbox[length - 1].year
              }
            ></BhboxDropdown>
            {AGRYTOOLS.includes(this.props.reportTypes[0]) ? (
              this.props.reportTypes[0] === "pulv" ? null : (
                <CadDropdown
                  leftIcon="event"
                  label={this.soilHelper(this.props.lastUsedSoil)}
                  active={this.props.soilDropdownActive}
                  onClick={this.props.toggleSoilDropdown}
                  onSendClick={this.handleCadSendClick}
                  rightNeighbor={true}
                  radioSelect={this.props.lastUsedCad}
                />
              )
            ) : (
              <WeatherDropdown
                leftIcon="water_drop"
                label={this.props.lastUsedCad + " mm"}
                active={this.state.openCadDropdown}
                onClick={this.toggleCadDropdown}
                data={this.props.report.report}
                checkedData={this.state.checkedData}
                onChange={this.handleCheckData}
                onSendClick={this.weatherHandleSendClick}
                selectedYear={this.selectedYear}
                graphicControl={false}
                yearBegin={this.state.weather.yearBegin}
                optionsType={"variables"}
                weatherType={this.props.reportTypes[0]}
                weatherGraphicType={this.props.graphicType}
                fourthDrop={false}
                radioSelect={this.state.variablesSelect}
                rightNeighbor={true}
                isCadDropdown={true}
              />
            )}
            {this.props.reportTypes[0] === "bhbox" ? (
               <>
                <VisualizationDropdown
                  visualizationType={this.props.visualizationType}
                  handleVisualizationType={this.visualizationSendClick}
                  selectedFarm={this.state.selectedFarm}
                  selectedYear={this.selectedYear}
                  graphicType={this.props.reportTypes[0]}
                />
                <BhboxFilter
                  onChange={this.onDecendioChange}
                  filterParamsFromPeriod={this.props.filterParams.fromPeriod}
                  filterParamsToPeriod={this.props.filterParams.toPeriod}
                  visualizationType={this.props.visualizationType}
                  handlePeriodFilter={this.handlePeriodFilter}
                />
              </>
            ) : null}
          </Fragment>
        );
      }
      //empty header when loading
    } else {
      return (
        <div>
          <div className="cols3"></div>
          <div className="cols3"></div>
        </div>
      );
    }
  }

  renderReport() {
    let setReport = (
      reportTypes,
      weatherGraphic,
      graphic,
      data,
      fullScreen,
      farms,
      bhboxType,
      activeFarm,
      activeBhboxVariable
    ) => {
      if (graphic) {
        return (
          <GraphicChart
            data={data}
            fullScreen={fullScreen}
            activeVariable={activeBhboxVariable}
            activeFarm={activeFarm === undefined ? -1 : activeFarm.id}
            selectedGridPoint={this.props.selectedGridPointCoords}
            numberOfYears={this.state.bhboxRange}
            visualizationType={this.props.visualizationType}
          />
        );
      } else if (
        weatherGraphic &&
        reportTypes[0] !== "atrmax" &&
        reportTypes !== "favo"
      ) {
        return (
          <WeatherGraphic
            graphicType={this.props.graphicType}
            graphicRange={this.props.graphicRange}
            yearBegin={this.state.weather.yearBegin}
            yearEnd={this.state.weather.yearEnd}
            data={data}
            fullScreen={fullScreen}
            reportTypes={reportTypes}
            checkedData={this.state.checkedData}
            temperatureControl={this.state.temperatureControl}
            compareYears={this.state.weatherCompareYears}
            temperatureFilter={this.state.temperatureFilter}
            climatologyCompare={this.state.climatologyCompare}
            startDate={this.props.filterParams.startDate}
            endDate={this.props.filterParams.endDate}
            startYear={this.props.visualizationType === 'civil'
              ? this.state.startYear
              : this.state.startYearAgricultural
            }
            endYear={this.props.graphicType === 'anomaly'
              ? this.props.visualizationType === 'civil'
              ? this.state.endYearAnomaly
              : this.state.endYearAgriculturalAnomaly
              : this.props.visualizationType === 'civil' 
              ? this.state.endYear
              : this.state.endYearAgricultural
            }
            setStartAndEndDate={this.setStartAndEndDate}
          />
        );
      } else if (
        this.props.reportTypes[0] === "bhbox" ||
        (this.props.reportTypes[0] === "extbh" && !graphic)
      ) {
        return (
          <Bhbox
            filterParams={this.props.filterParams}
            farms={farms}
            bhboxType={bhboxType}
            activeFarm={activeFarm}
            data={data}
            fullScreen={fullScreen}
            agrytoolsType={this.props.reportTypes[0]}
            soil={this.props.lastUsedSoil}
            xavierGridPoints={this.props.xavierGrid}
            visualizationType={this.props.visualizationType}
          />
        );
      } else if (this.props.reportTypes[0] === "atrmax") {
        return (
          <AtrMax
            data={data}
            yearBegin={this.state.weather.yearBegin}
            yearEnd={this.state.weather.yearEnd}
            compareYears={this.state.weatherCompareYears}
            fullScreen={fullScreen}
            activeVariable={activeBhboxVariable}
            activeFarm={activeFarm === undefined ? -1 : activeFarm.id}
            selectedGridPoint={this.props.selectedGridPointCoords}
            numberOfYears={this.state.bhboxRange}
            fromIndex={this.props.filterParams.fromIndex}
            toIndex={this.props.filterParams.toIndex}
            currentGraphic={this.state.currentGraphic}
          />
        );
      } else if (this.props.reportTypes[0] === "fmaac") {
        if (this.state.bhboxType === true)
          this.setState({
            bhboxType: false,
          });
        return (
          <AgryFire
            farms={farms}
            bhboxType={false}
            activeFarm={activeFarm}
            data={data}
            fullScreen={fullScreen}
            agrytoolsType={this.props.reportTypes[0]}
            soil={this.props.lastUsedSoil}
            xavierGridPoints={this.props.xavierGrid}
          />
        );
      } else if (
        reportTypes === "favo" &&
        data &&
        this.props.report.report !== undefined
      ) {
        return (
          <ClimateFavorabilityReportChart
            agryindexAndAgryfiredata={data}
            currentSelectedYearOFClimateFavorability={
              this.state.currentSelectedYearOFClimateFavorability
            }
          />
        );
      } else {
        return (
          <AgryIndex
            farms={farms}
            bhboxType={bhboxType}
            activeFarm={activeFarm}
            data={data}
            fullScreen={fullScreen}
            agrytoolsType={this.props.reportTypes[0]}
            soil={this.props.lastUsedSoil}
            xavierGridPoints={this.props.xavierGrid}
            setMapDay={this.props.setMapDay}
            generateMapData={this.props.generateMapData}
            toggleLoader={this.props.toggleLeftLoader}
            generatingMapData={this.props.generatingMapData}
            lastUsedSoil={this.props.lastUsedSoil}
            lastUsedCad={this.props.lastUsedCad}
            setMapYear={this.props.setMapYear}
          />
        );
      }
    };

    if (this.props.report !== undefined) {
      let farm = undefined;
      if (this.props.farms !== undefined) {
        farm = this.props.farms["farms"].filter(
          (farm) => farm.id === this.props.activeFarm
        )[0];
      }
      return (
        <div
          className={classnames(
            "report--content",
            { "multiple-farms": this.state.bhboxType },
            {
              "bar-graphic":
                this.props.reportTypes[0] === "prec" ||
                this.props.graphicType === "anomaly",
            }
          )}
        >
          {setReport(
            this.props.reportTypes,
            this.state.weatherGraphic,
            this.state.graphicActive,
            this.state.filteredData,
            this.props.fullScreen,
            this.props.farms,
            this.state.bhboxType,
            farm,
            this.state.bhboxExcDefAux
          )}
          {this.renderMultipleYearsGraphic()}
          <ReportFooter
            graphicActive={this.state.graphicActive}
            weatherGraphic={this.state.weatherGraphic}
            reportTypes={this.props.reportTypes}
            lastUsedCad={this.props.lastUsedCad}
          />
        </div>
      );
    } else {
      return <div className="loading-screen">Calculando...</div>;
    }
  }

  bhboxHeader() {
    if (
      this.props.reportTypes[0] === "bhbox" ||
      this.props.reportTypes[0] === "extbh"
    ) {
      if (this.state.graphicActive) {
        return "";
      } else {
        return "Armazenamento Relativo De Água No Solo (ARM/CAD , %)";
      }
    } else if (this.props.reportTypes[0] === "plan") {
      return "Condição Para Plantio/Semeadura";
    } else if (this.props.reportTypes[0] === "herb") {
      return "Aplicação de Herbicidas";
    } else if (this.props.reportTypes[0] === "ps") {
      return "Condições para Preparo do Solo";
    } else if (this.props.reportTypes[0] === "traf") {
      return "Condições de Tráfego e Colheita";
    } else if (this.props.reportTypes[0] === "fert") {
      return "Adubação";
    } else if (this.props.reportTypes[0] === "cresc") {
      return "Condições de Crescimento das Culturas";
    } else if (this.props.reportTypes[0] === "pulv") {
      return "Favorabilidade de Pulverização";
    }
  }

  bhname(name, initials) {
    if (name === "") {
      return "Ponto " + initials;
    } else {
      return name;
    }
  }

  renderFarmInfo() {
    let farm = undefined;
    if (this.props.report !== undefined) {
      if (this.props.farms !== undefined) {
        farm = this.props.farms["farms"].filter(
          (farm) => farm.id === this.props.activeFarm
        )[0];
      }
      let farmNameWithYear = "";
      if (farm === undefined) {
        farmNameWithYear = this.props.selectedGridPointName;
      } else {
        farmNameWithYear =
          farm.name === "" ? "Ponto " + farm.initials : farm.name;
      }

      if (
        (this.props.graphicType === "period") &&
        this.props.graphicRange !== "annualy" &&
        this.props.reportTypes[0] !== "bhbox" &&
        this.props.reportTypes[0] !== "fmaac"
      ) {
        farmNameWithYear = this.props.visualizationType === 'civil'
          ? `${farmNameWithYear} | ${this.selectedYear}`
          : `${farmNameWithYear} | ${this.selectedYear} - ${(Number(this.selectedYear) + 1).toString()}`;
      }

      if (this.state.weatherGraphic) {
        farmNameWithYear = farmNameWithYear + " | " + this.weatherHeader();
      }

      if (this.props.reportTypes[0] === "bhbox") {
        farmNameWithYear += " | Armazenamento Relativo De Água No Solo";
      }

      if (this.props.reportTypes[0] === "extbh") {
        farmNameWithYear += " | Déficit hídrico x Excedente hídrico";
      }

      if (this.props.reportTypes[0] === "fmaac") {
        farmNameWithYear += " | Agryfire";
      }

      if (AGRYTOOLS.includes(this.props.reportTypes[0])) {
        farmNameWithYear = farmNameWithYear + " | " + this.bhboxHeader();
      }

      if (this.props.reportTypes[0] === "gd") {
        farmNameWithYear =
          farmNameWithYear + " | Tb: " + this.props.tBasal + " °C";
      }

      if (
        ["arm", "etretp", "excdef", "bhbox", "extbh"].includes(
          this.props.reportTypes[0]
        )
      ) {
        if (this.state.variablesSelect !== "etp") {
          farmNameWithYear =
            farmNameWithYear + " | CAD = " + this.props.lastUsedCad + " mm";
        }
      }

      if (this.state.bhboxType && !this.state.graphicActive) {
        return (
          <div className="col s12 bhbox--labels-farm-name">
            {this.selectedYear}
          </div>
        );
      } else {
        if (this.props.reportTypes === "favo") {
          farmNameWithYear += ` | Favorabilidade Climática | ${this.state.currentSelectedYearOFClimateFavorability}`;
        }
        return (
          <div
            className={classnames("row farm-info-header", {
              "farm-info-header-weather":
                this.props.reportTypes[0] !== "bhbox" &&
                !AGRYTOOLS.includes(this.props.reportTypes[0]) &&
                !this.agryfire.includes(this.props.reportTypes[0]),
            })}
          >
            <div className="general-prod-disclaimer">
              <span>*</span>
              {`Última atualização: ${new Date(
                Date.now() - 86400000
              ).toLocaleDateString("pt-BR")} ${
                this.props.reportTypes[0] !== "fmaac" &&
                !["arm", "etretp", "excdef", "bhbox", "extbh"].includes(
                  this.props.reportTypes[0]
                ) &&
                !["plan", "herb", "ps", "fert", "traf", "cresc"].includes(
                  this.props.reportTypes[0]
                )
                  ? "Previsão por NOAA e INMET"
                  : ""
              }`}
            </div>

            <div
              style={{ margin: "0" }}
              className="col s12 bhbox--labels-farm-name bhbox--labels-farm-name-weather"
            >
              {this.props.activeFarm !== -1
                ? farmNameWithYear
                : farmNameWithYear}
            </div>
          </div>
        );
      }
    }
  }

  //#endregion

  //#region Render methods

  filterBHBoxData = (data, yearsToFilter) => {
    if (!data && data.length === 0) return [];

    if (!yearsToFilter && yearsToFilter.length === 0) return data;

    let filteredData = [];

    data.forEach((row) => {
      let newData = {
        farm_id: row["farm_id"],
        bhbox: [],
      };

      let newBhbox = [];

      row["bhbox"].forEach((year) => {
        if (yearsToFilter.includes(year["year"].toString()))
          newBhbox.push(year);
      });

      newData["bhbox"] = newBhbox;

      filteredData.push(newData);
    });

    return filteredData;
  };

  handleXavierBhbox = () => {
    this.props.allowXavierBhbox();
    this.props.toggleReportContainer();
  };

  toggleActive = () => {
    this.props.toggleReportContainer();
  };

  toggleWeatherGraphicTypeDropdown = () => {
    this.setState({
      weatherGraphicTypeControl: !this.state.weatherGraphicTypeControl,
    });
  };

  toggleWeatherVariableDropdown = () => {
    this.setState({
      weatherVariableControl: !this.state.weatherVariableControl,
    });
  };

  toggleCadDropdown = () => {
    this.setState({ openCadDropdown: !this.state.openCadDropdown });
  };

  toggleVisualizationDropdown = () => {
    this.setState({
      openVisualizationDropdown: !this.state.openVisualizationDropdown,
    });
  };

  toggleGraphicActive = () => {
    let dayAndMonth = this.props.weatherMapDay["label"].split("/");
    let year = this.props.weatherMapDay["year"];
    let variable = undefined;
    let mapAttrs = {
      initial_date:
        year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0],
      final_date: year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0],
      type: "period",
      range: "daily",
      cad: this.props.lastUsedCad,
      tb: this.props.tBasal,
      soil_texture: this.props.lastUsedSoil,
      visualization_type: this.props.visualizationType,
    };

    if (this.state.graphicActive) {
      this.setState({
        graphicActive: !this.state.graphicActive,
        bhboxType: false,
      });

      this.props.setReportTypes(["bhbox"]);
      variable = "bhbox";
    } else {
      this.setState({
        graphicActive: !this.state.graphicActive,
      });
      this.props.setReportTypes(["extbh"]);
      variable = "excdef";
    }

    let dayInfo = {
      label: this.props.weatherMapDay["label"],
      year: year,
      variable: variable,
      initial_date:
        year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0],
      final_date: year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0],
    };
    this.props.setMapDay(dayInfo);
    this.props.generatingMapData();
    if (this.props.leftLoader === true) {
      this.props.toggleLeftLoader();
    } else {
      this.props.toggleLeftLoader();
    }
    this.props.generateMapData(variable, mapAttrs);
  };

  toggleDefGraphicActive = () => {
    this.setState({
      graphicActive: !this.state.graphicActive,
      bhboxExcDefAux: "def",
    });
  };

  toggleExcGraphicActive = () => {
    this.setState({
      graphicActive: !this.state.graphicActive,
      bhboxExcDefAux: "exc",
    });
  };

  toggleSchedule = (event) => {
    if (!event || event.target.localName !== "li")
      this.setState({
        showSchedule: !this.state.showSchedule,
      });
  };

  clearCheckedData = () => {
    this.setState({
      checkedData: [],
      bhBoxCheckedData: {
        EN: [],
        LN: [],
        N: [],
        all: [],
      },
    });
  };

  clearFarmsCheckedData = () => {
    this.setState({
      farmsCheckedData: [],
    });
  };

  setStartAndEndDate = (range) => {
    let startDate = '';
    let endDate = '';
    if (this.props.visualizationType === 'agricultural') {
      switch (range) {
        case 'daily':
          startDate = '01/07';
          endDate = '30/06';
          break;
        case 'decendial':
          startDate = 'jul_1';
          endDate = 'jun_3';
          break;
        case 'monthly':
          startDate = 'jul';
          endDate = 'jun';
          break;
        default:
          startDate = '01/07';
          endDate = '30/06';
          break;
      }
    } else {
      switch (range) {
        case 'daily':
          startDate = '01/01';
          endDate = '31/12';
          break;
        case 'decendial':
          startDate = 'jan_1';
          endDate = 'dez_3';
          break;
        case 'monthly':
          startDate = 'jan';
          endDate = 'dez';
          break;
        default:
          startDate = '01/01';
          endDate = '31/12';
          break;
      }
    }
    this.setState({ startDate, endDate });
  }

  //handle the selected data from the checkboxes on the bhbox
  handleCheckData = (newData, selectorType = null) => {
    if (newData.target["name"] === "all_farms") {
      let selectedFarms = this.state.farmsCheckedData.slice();

      if (newData.target["checked"]) {
        this.props.farms["farms"].forEach((farm) => {
          if (!selectedFarms.includes(farm["id"].toString())) {
            selectedFarms.push(farm["id"].toString());
          }
        });

        this.setState({
          farmsCheckedData: selectedFarms,
        });
      } else {
        this.setState({
          farmsCheckedData: [],
        });
      }
    } else if (newData.target["id"] === "all") {
      let selected = this.state.checkedData.slice();
      const { bhBoxCheckedData, bhBoxSelectedEnoEvent } = this.state;

      if (newData.target["checked"]) {
        this.props.enos.forEach((year) => {
          if (!selected.includes(year.toString()))
            selected.push(year.toString());

          if (
            !bhBoxCheckedData[bhBoxSelectedEnoEvent].includes(year.toString())
          )
            bhBoxCheckedData[bhBoxSelectedEnoEvent].push(year.toString());
        });

        this.setState({
          checkedData: selected.slice(),
          bhBoxCheckedData: bhBoxCheckedData,
        });
      } else {
        bhBoxCheckedData[bhBoxSelectedEnoEvent] = [];

        this.setState({
          checkedData: [`${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`],
          bhBoxCheckedData,
        });
      }
    } else if (newData.length < 1) {
      if (selectorType === "points") {
        this.setState({
          farmsCheckedData: newData,
        });
      } else {
        this.setState({
          checkedData: newData,
        });
      }
    } else if (newData.length > 1) {
      if (selectorType === "points") {
        this.setState({
          farmsCheckedData: newData,
        });
      } else {
        this.setState({
          checkedData: newData,
        });
      }
    } else {
      let data = undefined;
      if (selectorType === "points") {
        data = this.state.farmsCheckedData;
        if (newData.target["checked"]) {
          data.push(newData.target["id"].toString());
          this.setState({
            farmsCheckedData: data,
          });
        } else {
          if (
            this.state.farmsCheckedData.includes(
              newData.target["id"].toString()
            )
          ) {
            let index = this.state.farmsCheckedData.indexOf(
              newData.target["id"].toString()
            );
            data.splice(index, 1);
            this.setState({
              farmsCheckedData: data,
            });
          }
        }
      } else {
        data = this.state.checkedData;
        let bhBoxCheckedDataCopy = this.state.bhBoxCheckedData;
        let selectedEnoEvent = this.state.bhBoxSelectedEnoEvent;

        if (newData.target["checked"]) {
          data.push(newData.target["id"].toString());
          bhBoxCheckedDataCopy[selectedEnoEvent].push(
            newData.target["id"].toString()
          );

          this.setState({
            checkedData: data,
            bhBoxCheckedData: bhBoxCheckedDataCopy,
          });
        } else {
          let nextState = {};

          if (
            this.state.checkedData.includes(newData.target["id"].toString())
          ) {
            let index = this.state.checkedData.indexOf(
              newData.target["id"].toString()
            );

            data.splice(index, 1);
            nextState.checkedData = data;
          }

          if (
            bhBoxCheckedDataCopy[selectedEnoEvent].includes(
              newData.target["id"].toString()
            )
          ) {
            let index = bhBoxCheckedDataCopy[selectedEnoEvent].indexOf(
              newData.target["id"].toString()
            );

            bhBoxCheckedDataCopy[selectedEnoEvent].splice(index, 1);
            nextState.bhBoxCheckedDataCopy = bhBoxCheckedDataCopy;
          }

          if (nextState.checkedData || nextState.bhBoxCheckedDataCopy) {
            this.setState(nextState);
          }
        }
      }
    }
  };

  //Get the right dates for when a dropdown of year or variables is changed
  getDatesToSameChart = (variable = null, year = null) => {
    let dayAndMonth = [];
    let dayInfo = {};
    let expectedYear = year === null ? this.props.weatherMapDay["year"] : year;
    let formattedDates = undefined;
    let subtractYear = false;

    if (this.props.reportTypes[0] !== "temperatura") {
      subtractYear =
        year === null
          ? agriculturalYearSubtraction(
              this.props.visualizationType,
              this.props.report.report[0]
            )
          : false;
    }

    if (
      this.props.graphicRange === "daily" &&
      this.props.reportTypes[0] !== "excdef"
    ) {
      dayAndMonth = this.props.weatherMapDay["label"].split("/");
      dayInfo = {
        label: this.props.weatherMapDay["label"],
        year: subtractYear ? expectedYear - 1 : expectedYear,
        variable: variable,
        initial_date: subtractYear
          ? expectedYear -
            1 +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString()
          : expectedYear +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString(),
        final_date: subtractYear
          ? expectedYear -
            1 +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString()
          : expectedYear +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString(),
      };
    } else if (
      this.props.graphicRange === "decendial" ||
      this.props.reportTypes[0] === "excdef"
    ) {
      dayAndMonth = this.props.weatherMapDay["label"].split("_");
      formattedDates = setDates(
        monthToNumber(dayAndMonth[0]),
        dayAndMonth[1],
        expectedYear
      );

      dayInfo = {
        label: this.props.weatherMapDay["label"],
        year: expectedYear,
        variable: variable,
        initial_date: formattedDates[0],
        final_date: formattedDates[1],
      };
    } else if (this.props.graphicRange === "monthly") {
      dayAndMonth = this.props.weatherMapDay["label"].split("/");
      if (dayAndMonth.length > 1) {
        dayAndMonth = dayAndMonth[1];
      } else {
        dayAndMonth = this.props.weatherMapDay["label"].split("_");
        if (dayAndMonth.length > 1) {
          dayAndMonth = dayAndMonth[1];
        } else {
          dayAndMonth = this.props.weatherMapDay["label"];
        }
      }
      formattedDates = setDates(monthToNumber(dayAndMonth), "4", expectedYear);
      dayInfo = {
        label: this.props.weatherMapDay["label"],
        year: expectedYear,
        variable: variable,
        initial_date: formattedDates[0],
        final_date: formattedDates[1],
      };
    } else {
      formattedDates = setDates(undefined, "5", expectedYear);

      dayInfo = {
        label: this.props.weatherMapDay["label"],
        year: expectedYear,
        variable: variable,
        initial_date: formattedDates[0],
        final_date: formattedDates[1],
      };
    }
    return dayInfo;
  };

  getDatesToChartChange = (newType, newRange, variable) => {
    let biggestCompareYear =
      this.state.weatherCompareYears.length === 2
        ? this.state.weatherCompareYears[0] > this.state.weatherCompareYears[1]
          ? this.state.weatherCompareYears[0]
          : this.state.weatherCompareYears[1]
        : this.state.weatherCompareYears[0];
    let dayAndMonth = [];
    let dayInfo = {};
    let expectedYear =
      newType === "cmp" ? biggestCompareYear : this.props.weatherMapDay["year"];
    let formattedDates = undefined;

    if (this.props.graphicRange === newRange) {
      if (newRange === "daily") {
        dayAndMonth = this.props.weatherMapDay["label"].split("/");
        dayInfo = {
          label: this.props.weatherMapDay["label"],
          year: expectedYear,
          variable: variable,
          initial_date:
            expectedYear +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString(),
          final_date:
            expectedYear +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString(),
        };
      } else if (newRange === "decendial") {
        dayAndMonth = this.props.weatherMapDay["label"].split("_");
        formattedDates = setDates(
          monthToNumber(dayAndMonth[0]),
          dayAndMonth[1],
          expectedYear
        );

        dayInfo = {
          label: this.props.weatherMapDay["label"],
          year: expectedYear,
          variable: variable,
          initial_date: formattedDates[0],
          final_date: formattedDates[1],
        };
      } else if (newRange === "monthly") {
        dayAndMonth = this.props.weatherMapDay["label"];
        formattedDates = setDates(
          monthToNumber(dayAndMonth),
          "4",
          expectedYear
        );

        dayInfo = {
          label: this.props.weatherMapDay["label"],
          year: expectedYear,
          variable: variable,
          initial_date: formattedDates[0],
          final_date: formattedDates[1],
        };
      } else {
        formattedDates = setDates(undefined, "5", expectedYear);
        dayInfo = {
          label: undefined,
          year: expectedYear,
          variable: variable,
          initial_date: formattedDates[0],
          final_date: formattedDates[1],
        };
      }
    } else {
      if (this.props.graphicRange === "daily") {
        dayAndMonth = this.props.weatherMapDay["label"]
          ? this.props.weatherMapDay["label"].split("/")
          : undefined;
        if (newRange === "decendial") {
          dayAndMonth[0] = setDecendialNumber(parseInt(dayAndMonth[0], 10));
          dayAndMonth[1] =
            dayAndMonth[1] === undefined
              ? this.props.weatherMapDay.initial_date.substring(5, 7)
              : dayAndMonth[1];
          formattedDates = setDates(
            dayAndMonth[1],
            dayAndMonth[0],
            expectedYear
          );

          dayInfo = {
            label: numberToMonth(dayAndMonth[1]) + "_" + dayAndMonth[0],
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        } else if (newRange === "monthly") {
          dayAndMonth[1] =
            dayAndMonth[1] === undefined
              ? monthToNumber(dayAndMonth[0])
              : dayAndMonth[1];
          formattedDates = setDates(dayAndMonth[1], "4", expectedYear);
          dayInfo = {
            label: numberToMonth(dayAndMonth[1]),
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        } else {
          formattedDates = setDates(undefined, "5", expectedYear)
            .map((date) => {
              const prevYear = Number(date.slice(0, 4)) - 1;
              const monthAndDay = date.slice(4);
              return `${prevYear}${monthAndDay}`;
            });
          dayInfo = {
            label: undefined,
            year: (Number(expectedYear) - 1).toString(),
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        }
      } else if (this.props.graphicRange === "decendial") {
        dayAndMonth = this.props.weatherMapDay["label"]
          ? this.props.weatherMapDay["label"].split("_")
          : undefined;
        if (newRange === "daily") {
          let lastDay = this.setLastDay(
            dayAndMonth[1],
            monthToNumber(dayAndMonth[0].toString()),
            parseInt(expectedYear, 10)
          );
          dayInfo = {
            label: lastDay + "/" + monthToNumber(dayAndMonth[0].toString()),
            year: expectedYear,
            variable: variable,
            initial_date:
              expectedYear +
              "-" +
              monthToNumber(dayAndMonth[0].toString()) +
              "-" +
              lastDay,
            final_date:
              expectedYear +
              "-" +
              monthToNumber(dayAndMonth[0].toString()) +
              "-" +
              lastDay,
          };
        } else if (newRange === "monthly") {
          formattedDates = setDates(
            monthToNumber(dayAndMonth[0].toString()),
            "4",
            expectedYear
          );
          dayInfo = {
            label: dayAndMonth[0],
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        } else {
          formattedDates = setDates(undefined, "5", expectedYear);
          dayInfo = {
            label: undefined,
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        }
      } else if (this.props.graphicRange === "monthly") {
        dayAndMonth = this.props.weatherMapDay["label"];
        if (newRange === "daily") {
          let lastDay = this.setLastDay(
            "3",
            monthToNumber(dayAndMonth.toString()),
            parseInt(expectedYear, 10)
          );
          dayInfo = {
            label: lastDay + "/" + monthToNumber(dayAndMonth.toString()),
            year: expectedYear,
            variable: variable,
            initial_date:
              expectedYear +
              "-" +
              monthToNumber(dayAndMonth.toString()) +
              "-" +
              lastDay,
            final_date:
              expectedYear +
              "-" +
              monthToNumber(dayAndMonth.toString()) +
              "-" +
              lastDay,
          };
        } else if (newRange === "decendial") {
          let today = new Date();
          let day = today.getDate();

          if (day < 10) {
            day = "0" + day;
          }

          let currentDayDecendial = setDecendialNumber(parseInt(day, 10));
          formattedDates = setDates(
            monthToNumber(dayAndMonth.toString()),
            currentDayDecendial,
            expectedYear
          );
          dayInfo = {
            label: dayAndMonth + "_" + currentDayDecendial,
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        } else {
          formattedDates = setDates(undefined, "5", expectedYear);
          dayInfo = {
            label: undefined,
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        }
      } else {
        let compareDate = new Date();
        if (newRange === "daily") {
          let day = compareDate.getDate();
          let month = compareDate.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }

          if (day < 10) {
            day = "0" + day;
          }

          dayInfo = {
            label:
              compareDate.getFullYear() === parseInt(expectedYear, 10)
                ? day + "/" + month
                : "31/12",
            year: expectedYear,
            variable: variable,
            initial_date:
              compareDate.getFullYear() === parseInt(expectedYear, 10)
                ? expectedYear + "-" + month + "-" + day
                : expectedYear + "-12-31",
            final_date:
              compareDate.getFullYear() === parseInt(expectedYear, 10)
                ? expectedYear + "-" + month + "-" + day
                : expectedYear + "-12-31",
          };
        } else if (newRange === "decendial") {
          let month = compareDate.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          if (compareDate.getFullYear() !== parseInt(expectedYear, 10)) {
            month = "12";
          }

          formattedDates = setDates(
            monthToNumber(month.toString()),
            "3",
            expectedYear
          );
          dayInfo = {
            label:
              compareDate.getFullYear() === parseInt(expectedYear, 10)
                ? numberToMonth(month.toString()) + "_3"
                : "dez_3",
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        } else {
          let month = compareDate.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          formattedDates = setDates(month, "4", expectedYear);
          dayInfo = {
            label:
              compareDate.getFullYear() === parseInt(expectedYear, 10)
                ? numberToMonth(month.toString())
                : numberToMonth("12"),
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1],
          };
        }
      }
    }

    return dayInfo;
  };

  weatherHandleSendClick = (e = null, second = null, third = null) => {
    let day = undefined;
    let variable = undefined;
    let mapAttrs = {};
    let mapFlag = false;
    let indexVariables = ["plan", "herb", "ps", "fert", "traf", "cresc"];
    let bhboxVariables = ["bhbox", "etretp", "excdef"];

    if (
      e === "0" &&
      this.props.graphicType !== "cmp" &&
      this.props.graphicType !== "accumulate" &&
      this.props.reportTypes[0] !== "rainy_days"
    ) {
      return;
    }
    if (second === "begin") {
      let mapYear = undefined;
      if (
        this.props.graphicType === "cmp" ||
        this.props.graphicType === "accumulate"
      ) {
        if (this.state.checkedData.length > 0) {
          let years = this.state.checkedData.slice();
          if (years.length === 2) {
            let biggest = years[0] > years[1] ? years[0] : years[1];
            this.props.setMapYear(biggest);
            mapYear = biggest;
          } else {
            this.props.setMapYear(years[0]);
            mapYear = years[0];
          }
          this.setState({
            weatherCompareYears: this.state.checkedData.slice(),
            temperatureControl: this.state.temperatureControl,
          });
        }
      } else {
        if (e > this.state.weather.yearEnd) {
          this.setState({
            weather: {
              yearBegin: this.state.weather.yearEnd,
              yearEnd: e,
            },
          }, () => {
            this.setState({ beginSelect: this.state.weather.yearEnd, })
          });
        } else {
          this.setState({
            weather: {
              yearBegin: e,
              yearEnd: this.state.weather.yearEnd,
            },
          }, () => {
            this.setState({ beginSelect: e, })
          });
        }
        this.props.setMapYear(e);
        mapYear = e;
      }

      variable = ["temperatura", "etretp", "excdef"].includes(
        this.props.reportTypes[0]
      )
        ? this.state.temperatureControl
        : this.props.reportTypes[0];
      day = this.getDatesToSameChart(variable, mapYear);

      mapAttrs = {
        initial_date:
          this.props.reportTypes[0] === "bhbox" ||
          this.props.reportTypes[0] === "extbh"
            ? "2015-01-01"
            : day["initial_date"],
        final_date:
          this.props.reportTypes[0] === "bhbox" ||
          this.props.reportTypes[0] === "extbh"
            ? "2015-01-01"
            : day["final_date"],
        type:
          this.props.reportTypes[0] === "bhbox"
            ? "period"
            : this.props.graphicType !== undefined
            ? this.props.graphicType
            : "period",
        range:
          this.props.reportTypes[0] === "excdef"
            ? "decendial"
            : this.props.reportTypes[0] === "bhbox"
            ? "daily"
            : this.props.graphicRange !== undefined
            ? this.props.graphicRange
            : "daily",
        cad: 75,
        tb: this.props.tBasal,
        soil_texture: this.props.lastUsedSoil,
        visualization_type: this.props.visualizationType,
      };

      if (
        (this.props.reportTypes[0] === "etretp" &&
          this.state.temperatureControl === "etretp") ||
        ["atrmax", "heat_wave", "frost_risk", "rainy_days"].includes(
          this.props.reportTypes[0]
        ) ||
        this.props.graphicType === "climatology"
      ) {
        mapFlag = false;
      } else {
        mapFlag = true;
      }
    } else if (
      second === "end" &&
      (this.props.graphicType === "period" ||
        this.props.graphicType === "anomaly")
    ) {
      this.setState({
        weather: {
          yearBegin: this.state.weather.yearBegin,
          yearEnd: e,
        },
        endSelect: e,
      });
    } else if (second === "graphic") {
      let farm = undefined;
      if (this.props.farms !== undefined) {
        farm = this.props.farms["farms"].filter(
          (farm) => farm.id === this.props.activeFarm
        )[0];
      }
      let farmAttrs = undefined;
      if (farm === undefined) {
        let gridCoords =
          this.props.selectedGridPointCoords.lat +
          "," +
          this.props.selectedGridPointCoords.lng;
        farmAttrs = {
          coords: gridCoords,
          farmId: -1,
          cad: 0,
          type: e,
          range: third,
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          min_prec: this.state.min_prec,
        };
      } else {
        farmAttrs = {
          name: farm.name,
          coords: farm.coords,
          farmId: this.props.activeFarm,
          cad: farm.cad,
          initials: farm.initials,
          type: e,
          range: third,
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          min_prec: this.state.min_prec,
        };
      }

      if (
        this.props.reportTypes.includes("etretp") === true &&
        this.state.temperatureControl !== "" &&
        e === "cmp"
      ) {
        this.setState({
          temperatureControl: "etr",
        });
      }
      this.setState({
        radioSelect: e,
        rangeSelect: third,
      });

      variable = ["temperatura", "etretp"].includes(this.props.reportTypes[0])
        ? this.state.temperatureControl
        : this.props.reportTypes[0];
      day = this.getDatesToChartChange(e, third, variable);
      mapAttrs = {
        initial_date:
          this.props.reportTypes[0] === "bhbox" ||
          this.props.reportTypes[0] === "extbh"
            ? "2015-01-01"
            : day["initial_date"],
        final_date:
          this.props.reportTypes[0] === "bhbox" ||
          this.props.reportTypes[0] === "extbh"
            ? "2015-01-01"
            : day["final_date"],
        type: this.props.reportTypes[0] === "bhbox" ? "period" : e,
        range:
          this.props.reportTypes[0] === "excdef"
            ? "decendial"
            : this.props.reportTypes[0] === "bhbox"
            ? "daily"
            : third,
        cad: 75,
        tb: this.props.tBasal,
        soil_texture: this.props.lastUsedSoil,
        visualization_type: this.props.visualizationType,
      };

      if (e === "cmp") {
        this.props.setMapYear(
          this.state.weatherCompareYears.length === 2
            ? this.state.weatherCompareYears[0] >
              this.state.weatherCompareYears[1]
              ? this.state.weatherCompareYears[0]
              : this.state.weatherCompareYears[1]
            : this.state.weatherCompareYears[0]
        );
      }

      if (
        (this.props.reportTypes[0] === "etretp" &&
          this.state.temperatureControl === "etretp") ||
        ["heat_wave", "frost_risk", "rainy_days"].includes(
          this.props.reportTypes[0]
        )
      ) {
        mapFlag = false;
      } else {
        mapFlag = true;
      }

      this.props.setWeatherTypes(e, third);
      this.props.toggleReportUndefined();
      this.props.generateReport(this.props.reportTypes, farmAttrs);
    } else if (second === "variables") {
      day = this.getDatesToSameChart(e, this.props.weatherMapDay.year);

      mapAttrs = {
        initial_date: day["initial_date"],
        final_date: day["final_date"],
        type:
          this.props.reportTypes[0] === "bhbox"
            ? "period"
            : this.props.graphicType !== undefined
            ? this.props.graphicType
            : "period",
        range:
          this.props.reportTypes[0] === "excdef"
            ? "decendial"
            : this.props.reportTypes[0] === "bhbox"
            ? "daily"
            : this.props.graphicRange !== undefined
            ? this.props.graphicRange
            : "daily",
        cad: 75,
        tb: this.props.tBasal,
        soil_texture: this.props.lastUsedSoil,
        visualization_type: this.props.visualizationType,
      };

      this.setState({
        temperatureControl: e,
        variablesSelect: e,
      });


      if (this.props.reportTypes[0] === "rainy_days") {
        let farm = undefined;
        if (this.props.farms !== undefined) {
          farm = this.props.farms["farms"].filter(
            (farm) => farm.id === this.props.activeFarm
          )[0];
        }
        let farmAttrs = undefined;

        farmAttrs = {
          name: farm.name,
          coords: farm.coords,
          farmId: this.props.activeFarm,
          cad: farm.cad,
          initials: farm.initials,
          type: this.props.graphicType,
          range: this.props.graphicRange,
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          min_prec: e,
        };

        this.setState(
          {
            min_prec: e,
          },
          () => {
            this.props.toggleReportUndefined();
            this.props.generateReport(this.props.reportTypes, farmAttrs);
          }
        );
      } else if (e === "5" || e === "10" || e === "15" || e === "20") {
        let tb = parseFloat(e);
        let farm = undefined;
        if (this.props.farms !== undefined) {
          farm = this.props.farms["farms"].filter(
            (farm) => farm.id === this.props.activeFarm
          )[0];
        }
        let farmAttrs = undefined;
        if (farm === undefined) {
          let gridCoords =
            this.props.selectedGridPointCoords.lat +
            "," +
            this.props.selectedGridPointCoords.lng;
          farmAttrs = {
            coords: gridCoords,
            farmId: -1,
            cad: 0,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: tb,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        } else {
          farmAttrs = {
            name: farm.name,
            coords: farm.coords,
            farmId: this.props.activeFarm,
            cad: farm.cad,
            initials: farm.initials,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: tb,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        }
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);
        this.props.setTBasal(tb);
        mapAttrs["tb"] = tb;
      } else if (CADS.includes(e)) {
        let armCad = parseFloat(e);
        let farm = undefined;
        if (this.props.farms !== undefined) {
          farm = this.props.farms["farms"].filter(
            (farm) => farm.id === this.props.activeFarm
          )[0];
        }
        let farmAttrs = undefined;

        if (farm === undefined) {
          let gridCoords =
            this.props.selectedGridPointCoords.lat +
            "," +
            this.props.selectedGridPointCoords.lng;
          farmAttrs = {
            coords: gridCoords,
            farmId: -1,
            cad: armCad,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: 0,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        } else if (this.state.farmsCheckedData.length > 1) {
          farmAttrs = {
            coords: "",
            farmId: -1,
            cad: armCad,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: 0,
            user_points: this.state.farmsCheckedData,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        } else {
          farmAttrs = {
            name: farm.name,
            coords: farm.coords,
            farmId: this.props.activeFarm,
            cad: armCad,
            initials: farm.initials,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: 0,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        }
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);

        const type = this.props.reportTypes[0];

        if (
          type === "bhbox" ||
          type === "extbh" ||
          type === "etretp" ||
          type === "arm" ||
          type === "excdef"
        ) {
          this.props.setLastVisualizedCad(e);
        }

        this.props.setLastUsedCad(e);

        mapAttrs["cad"] = parseInt(e, 10);
      } else if (this.props.reportTypes[0] === "atrmax") {
        let farm = undefined;
        if (this.props.farms !== undefined) {
          farm = this.props.farms["farms"].filter(
            (farm) => farm.id === this.props.activeFarm
          )[0];
        }
        let farmAttrs = undefined;
        if (farm === undefined) {
          let gridCoords =
            this.props.selectedGridPointCoords.lat +
            "," +
            this.props.selectedGridPointCoords.lng;
          farmAttrs = {
            coords: gridCoords,
            farmId: -1,
            cad: 75,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: 0,
            cultivate_type: e,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        } else {
          farmAttrs = {
            name: farm.name,
            coords: farm.coords,
            farmId: this.props.activeFarm,
            cad: farm.cad,
            initials: farm.initials,
            type: this.props.graphicType,
            range: this.props.graphicRange,
            initial_date: "1980",
            final_date: (new Date().getFullYear() + 1).toString(),
            tb: 0,
            cultivate_type: e,
            visualization_type: this.props.visualizationType,
            min_prec: this.state.min_prec,
          };
        }
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);
      } else if (this.props.reportTypes[0] === 'heat_wave') {
        const farm = this.props.farms["farms"].find((f) => f.id === this.props.activeFarm);

        const farmAttrs = {
          name: farm.name,
          coords: farm.coords,
          farmId: this.props.activeFarm,
          cad: farm.cad,
          type: this.props.graphicType,
          range: this.props.graphicRange,
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          variable: 'heat_wave', 
          min_temp: e.toString(), 
        };

        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);
      }

      variable =
        this.props.reportTypes[0] === "gd"
          ? "gd"
          : this.props.reportTypes[0] === "extbh"
          ? "excdef"
          : e === "tmed"
          ? "tmean"
          : CADS.includes(e)
          ? "arm"
          : e;
      variable =
        CADS.includes(e) &&
        (indexVariables.includes(this.props.reportTypes[0]) ||
          bhboxVariables.includes(this.props.reportTypes[0]))
          ? this.props.reportTypes[0]
          : variable;
      variable =
        this.props.reportTypes[0] === "etretp"
          ? this.state.temperatureControl === "etr"
            ? "etp"
            : this.state.temperatureControl === "etp"
            ? "etr"
            : variable
          : variable;
      if (this.props.reportTypes[0] === "excdef") {
        variable = this.state.temperatureControl === 'exc' ? 'def' : 'exc';
        mapAttrs.soil_texture = this.props.lastUsedSoil;
      }

      day["variable"] = variable;

      if (
        e !== "etretp" &&
        !["atrmax", "rainy_days", "heat_wave"].includes(this.props.reportTypes[0]) &&
        variable !== "etretp"
      ) {
        mapFlag = true;
      }
    }
    if (mapFlag) {

      if(this.props.reportTypes[0] === 'etretp' && mapAttrs.type === 'anomaly') {
        this.props.setMapDay(day);
        this.props.generatingMapData();
        this.props.toggleLeftLoader();
      } else {
        this.props.setMapDay(day);
        this.props.generatingMapData();
        this.props.toggleLeftLoader();
        this.props.generateMapData(variable, mapAttrs);
      }
    } else if (["etretp"].includes(variable)) {
      this.props.resetGrid();
    }
  };

  handleAgriculturalYearDoesNonExistent = (type) => {
    if (type === 'agricultural') {
      this.props.setMapYear(`${CURRENT_YEAR - 1}`);
      this.setState({
        weatherCompareYears: this.props.reportTypes[0] !== 'atrmax' ? [
          `${CURRENT_YEAR - 2}`,
          `${CURRENT_YEAR - 1}`,
        ] : [
          `${CURRENT_YEAR - 1}`,
          `${CURRENT_YEAR}`,
        ],
        endYearAgricultural:
        `${(CURRENT_YEAR - 1).toString().slice(2)}-${CURRENT_YEAR.toString().slice(2)}`,
        weather: {
          yearBegin: `${CURRENT_YEAR - 1}`,
          yearEnd: `${CURRENT_YEAR - 1}`,
        },
      })
    } else {
      this.props.setMapYear(`${CURRENT_YEAR}`);
      this.setState({
        weatherCompareYears: [
          `${CURRENT_YEAR - 1}`,
          `${CURRENT_YEAR}`,
        ],
        weather: {
          yearBegin: `${CURRENT_YEAR}`,
          yearEnd: `${CURRENT_YEAR}`,
        },
      })
    }
  }


  visualizationSendClick = (visualization = "civil") => {
    
    this.props.changeVisualizationYear(visualization);
    
    let startDate;
    let endDate;

    if (visualization === 'agricultural') {

      if (currentAgriculturalYearDoesNotExist && this.props.mapYear === CURRENT_YEAR.toString()) {
        this.handleAgriculturalYearDoesNonExistent(visualization);
      }

      switch (this.props.graphicRange) {
        case 'daily':
          startDate = '01/07';
          endDate = '30/06';
          break;
        case 'decendial':
          startDate = 'jul_1';
          endDate = 'jun_3';
          break;
        case 'monthly':
          startDate = 'jul';
          endDate = 'jun';
          break;
        case 'annualy':
          startDate = '1980';
          endDate = '2025';
          break;
        default:
          startDate = '01/07';
          endDate = '30/06';
          break;
      }

      this.props.changeFilterParams({
        fromPeriod: '01/07',
        toPeriod: '30/06',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
    } else {

      if (currentAgriculturalYearDoesNotExist && this.props.mapYear === `${CURRENT_YEAR - 1}`) {
        this.handleAgriculturalYearDoesNonExistent(visualization);
      }

      switch (this.props.graphicRange) {
        case 'daily':
          startDate = '01/01';
          endDate = '31/12';
          break;
        case 'decendial':
          startDate = 'jan_1';
          endDate = 'dez_3';
          break;
        case 'monthly':
          startDate = 'jan';
          endDate = 'dez';
          break;
        default:
          startDate = '01/01';
          endDate = '31/12';
          break;
      }

      this.props.changeFilterParams({
        fromPeriod: '01/01',
        toPeriod: '31/12',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
    }

    if (visualization === this.props.visualizationType) {
      return;
    }

    let day;
    let mapAttrs;
    let variable;

    day = this.props.weatherMapDay;

    const subtractYear = agriculturalYearSubtraction(
      visualization,
      this.props.report.report[0],
      day.year
    );

    day.year = subtractYear
      ? (parseInt(day.year, 10) - 1).toString()
      : visualization === "civil" &&
        this.props.visualizationType === "agricultural"
      ? parseInt(day.year, 10).toString()
      : day.year;

    mapAttrs = {
      initial_date: day.initial_date || setLabeltoDate(day),
      final_date: day.final_date || setLabeltoDate(day, false),
      type: this.props.graphicType,
      range: this.props.graphicRange,
      cad: this.props.lastUsedCad,
      tb: this.props.tBasal,
      soil_texture: this.props.lastUsedSoil,
      visualization_type: visualization,
    };

    let farm = undefined;
    if (this.props.farms !== undefined) {
      farm = this.props.farms["farms"].filter(
        (farm) => farm.id === this.props.activeFarm
      )[0];
    }
    let farmAttrs = undefined;

    if (farm === undefined) {
      let gridCoords =
        this.props.selectedGridPointCoords.lat +
        "," +
        this.props.selectedGridPointCoords.lng;
      farmAttrs = {
        coords: gridCoords,
        farmId: -1,
        cad: this.props.lastUsedCad,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        visualization_type: visualization,
        min_prec: this.state.min_prec,
      };
    } else if (this.state.farmsCheckedData.length > 1) {
      farmAttrs = {
        coords: "",
        farmId: -1,
        cad: this.props.lastUsedCad,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        user_points: this.state.farmsCheckedData,
        visualization_type: visualization,
        min_prec: this.state.min_prec,
      };
    } else {
      farmAttrs = {
        name: farm.name,
        coords: farm.coords,
        farmId: this.props.activeFarm,
        cad: this.props.lastUsedCad,
        initials: farm.initials,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        visualization_type: visualization,
        min_prec: this.state.min_prec,
      };
    }

    variable =
      this.props.reportTypes[0] === "extbh"
        ? "excdef"
        : this.props.reportTypes[0];
    day["variable"] = variable;
    this.selectedYear = day.year.toString();

    if (variable === 'excdef' && this.state.temperatureControl) {
      variable = this.setVariablesLabel(this.state.temperatureControl).toLowerCase();
    } else if (variable === 'temperatura') {
      variable = this.state.temperatureControl;
      day["variable"] = variable;
    }

    const bhBoxCheckedData = {
      EN: [],
      LN: [],
      N: [],
      all: [],
    };

    this.setState({
      checkedData: [`${new Date().getFullYear() - 1}`, `${new Date().getFullYear()}`],
      bhBoxCheckedData: bhBoxCheckedData,
    });

    if (this.props.reportTypes[0] !== 'atrmax') {
      this.props.generatingMapData();
      this.props.toggleLeftLoader();
      this.props.toggleReportUndefined();
      this.props.generateMapData(variable, mapAttrs);
      this.props.generateReport(this.props.reportTypes, farmAttrs);
      return;
    }

    this.props.toggleReportUndefined();
    this.props.generateReport(this.props.reportTypes, farmAttrs);
    this.props.setMapDay(day);
  };

  handleCadSendClick = (e, soil, text) => {
    let variable;
    const day = this.getDatesToSameChart(e);
    this.props.setLastUsedCad(e);

    let mapAttrs = {
      initial_date: day["initial_date"],
      final_date: day["final_date"],
      type: "period",
      range: "daily",
      cad: e,
      tb: this.props.tBasal,
      soil_texture: this.props.lastUsedSoil,
    };

    this.setState({
      temperatureControl: e,
      variablesSelect: e,
    });

    let armCad = parseFloat(e);
    let farm = undefined;
    if (this.props.farms !== undefined) {
      farm = this.props.farms["farms"].filter(
        (farm) => farm.id === this.props.activeFarm
      )[0];
    }
    let farmAttrs = undefined;

    if (farm === undefined) {
      let gridCoords =
        this.props.selectedGridPointCoords.lat +
        "," +
        this.props.selectedGridPointCoords.lng;
      farmAttrs = {
        coords: gridCoords,
        farmId: -1,
        cad: armCad,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        min_prec: this.state.min_prec,
      };
    } else if (this.state.farmsCheckedData.length > 1) {
      farmAttrs = {
        coords: "",
        farmId: -1,
        cad: armCad,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        user_points: this.state.farmsCheckedData,
        min_prec: this.state.min_prec,
      };
    } else {
      farmAttrs = {
        name: farm.name,
        coords: farm.coords,
        farmId: this.props.activeFarm,
        cad: armCad,
        initials: farm.initials,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: 0,
        min_prec: this.state.min_prec,
      };
    }
    this.props.toggleReportUndefined();
    this.props.generateReport(this.props.reportTypes, farmAttrs);

    this.props.setLastUsedCad(e);
    this.props.setLastUsedSoil(this.cadToSoil(e, text));

    mapAttrs["cad"] = parseInt(e, 10);
    variable = e;
    variable = this.props.reportTypes[0];

    day["variable"] = variable;
    this.props.setMapDay(day);
    this.props.generatingMapData();
    this.props.toggleLeftLoader();
    this.props.generateMapData(variable, mapAttrs);
  };

  handleTemperatureSendClick = (event) => {
    this.setState({
      temperatureFilter: event,
    });
  };

  updatedMap = (dates, id, soil, event) => {
    const subtractYear = agriculturalYearSubtraction(
      this.props.visualizationType,
      this.props.report.report[0],
      dates.year
    );
    dates.year = subtractYear
      ? (parseInt(dates.year, 10) - 1).toString()
      : dates.year;
    let mapAttrs = {
      initial_date: setLabeltoDate(dates),
      final_date: setLabeltoDate(dates),
      type:
        this.props.graphicType !== undefined
          ? this.props.graphicType
          : "period",
      range:
        this.props.graphicRange !== undefined
          ? this.props.graphicRange
          : "daily",
      cad:
        this.props.reportTypes[0] === "plan"
          ? 25
          : this.props.reportTypes[0] === "cresc"
          ? this.props.lastUsedCad
          : AGRYTOOLS.includes(this.props.reportTypes[0])
          ? 50
          : this.props.lastUsedCad,
      soil_texture: id === "soil"
        ? event 
        : this.state.farmsCheckedData.length === 1
        ? soil
        : this.props.lastUsedSoil,
      tb: this.props.tBasal,
      visualization_type: this.props.visualizationType,
    };

    this.props.setMapDay(dates);
    this.props.generatingMapData();
    this.props.toggleLeftLoader();
    this.props.generateMapData(
        this.props.reportTypes[0] === "extbh"
        ? ["excdef"]
          : this.props.reportTypes,
      mapAttrs
    );
  }

  handleSendClick = (event, id) => {
    let dates = undefined;
    let soil = '';

    if (id === "farms" && this.state.farmsCheckedData.length === 0) {
      return;
    }

    const boxProductType =
      this.props.reportTypes[0] === "fmaac" ? "agryfire" : "bhbox";

    if (id === "soil") {
      dates = this.getDatesToSameChart(this.props.reportTypes[0]);
      this.props.setLastUsedSoil(event);
    } else if (this.state.checkedData.length > 0 || event !== "") {
      let filteredData = [];
      let newData = { farm_id: this.props.activeFarm, bhbox: [] };
      let newBhbox = [];
      let yearsAux = [];
      var data = undefined;
      if (id === "farms") {
        data = this.state.farmsCheckedData;
      } else {
        if (this.props.reportTypes[0] === "bhbox") {
          const { checkedData, bhBoxCheckedData, bhBoxSelectedEnoEvent } =
            this.state;
          
          if (bhBoxSelectedEnoEvent !== "all") {
            bhBoxCheckedData.all.forEach((year) => {
              if (this.props.enos.includes(parseInt(year, 10))) {
                bhBoxCheckedData[bhBoxSelectedEnoEvent].push(year.toString());
              }
            }, this);
          }

          data = bhBoxCheckedData[bhBoxSelectedEnoEvent];

          const newCheckedData = checkedData.filter((year) =>
            bhBoxCheckedData[bhBoxSelectedEnoEvent].includes(year)
          );
          let newBhBoxCheckedData = {
            EN: [],
            LN: [],
            N: [],
          };

          newBhBoxCheckedData[bhBoxSelectedEnoEvent] =
            bhBoxCheckedData[bhBoxSelectedEnoEvent];
          newBhBoxCheckedData.all = bhBoxCheckedData[bhBoxSelectedEnoEvent];

          this.setState({
            checkedData: newCheckedData,
            bhBoxCheckedData: newBhBoxCheckedData,
          });
        } else {
          data = this.state.checkedData;
        }
      }

      if (this.state.graphicActive) {
        let compare = [];

        if (event === "1") {
          compare[0] = parseInt(this.selectedYear - 1, 10).toString();
          compare[1] = this.selectedYear;

          this.yearsInGraphic = 2;
          this.setState({
            bhboxRange: event,
          });
        } else if (event === "0") {
          compare[0] = this.selectedYear;

          this.yearsInGraphic = 1;
          this.setState({
            bhboxRange: event,
          });
        } else {
          this.selectedYear = event;

          if (this.yearsInGraphic === 2) {
            compare[0] = parseInt(this.selectedYear - 1, 10).toString();
            compare[1] = this.selectedYear;

            this.setState({ bhboxRange: "1" });
          } else {
            compare[0] = this.selectedYear.toString();
            this.yearsInGraphic = 1;

            this.setState({
              bhboxYear: event,
              weather: {
                yearBegin: event,
                yearEnd: event,
              },
            });
          }
          this.props.setMapYear(event);
          dates = this.getDatesToSameChart(this.props.reportTypes[0], event);
        }

        let filteredData = [];

        if (this.props.user["type"] === "bank") {
          this.props.report["report"].forEach((row, index) => {
            newData = {
              farm_id: -1,
              lat: row["lat"],
              cad: row["cad"],
              lng: row["lng"],
              bhbox: [],
            };
            row["bhbox"].map((year) => {
              if (compare.includes(year["year"].toString())) {
                newBhbox.push(year);
              }
              return newBhbox;
            });
            newData["bhbox"] = newBhbox;
            newBhbox = [];
            filteredData.push(newData);
          });
        } else {
          filteredData = this.filterBHBoxData(
            this.props.report["report"],
            compare
          );
        }

        this.setState({
          filteredData,
          bhboxType: false,
        });
      } else if (id === "years" && !this.state.bhboxType) {
        //Select years to one farm
        this.props.report["report"].map((row) => {
          if (row["farm_id"] === this.props.activeFarm) {
            newData["farm_id"] = row["farm_id"];
            row[boxProductType].map((year) => {
              if (data.includes(year["year"].toString())) {
                newBhbox.push(year);
              }
              return newBhbox;
            });
            newData[boxProductType] = newBhbox;
          }
          return newData;
        });
        filteredData.push(newData);
        yearsAux = data.slice();
        this.setState({
          yearsAuxiliarData: yearsAux,
          filteredData: filteredData,
          bhboxType: false,
        });
        dates = this.getDatesToSameChart(
          this.props.reportTypes[0],
          yearsAux[yearsAux.length - 1]
        );
        //Select year for multiple farms
      } else if (id === "years" && this.state.bhboxType) {
        this.props.report["report"].forEach((row, index) => {
          if (this.props.user["type"] === "bank") {
            newData = {
              farm_id: -1,
              lat: row["lat"],
              cad: row["cad"],
              lng: row["lng"],
              bhbox: [],
            };
            row["bhbox"].forEach((year) => {
              if (year["year"].toString() === event.toString()) {
                newBhbox.push(year);
              }
            });
            newData["bhbox"] = newBhbox;
            newBhbox = [];
            filteredData[index] = newData;
          } else {
            if (
              this.state.farmsCheckedData.includes(row["farm_id"].toString())
            ) {
              newData = { farm_id: "", bhbox: [] };
              newData["farm_id"] = row["farm_id"];
              row[boxProductType].map((year) => {
                if (year["year"].toString() === event.toString()) {
                  newBhbox.push(year);
                }
                return newBhbox;
              });
              newData[boxProductType] = newBhbox;
              newBhbox = [];
              filteredData[index] = newData;
            }
          }
        });
        this.setState({
          filteredData: filteredData,
          bhboxType: true,
          bhbboxYear: event.toString(),
        });
        this.selectedYear = event;
        this.props.setMapYear(this.selectedYear);
        dates = this.getDatesToSameChart(this.props.reportTypes[0], event);
      } else if (id === "farms" && this.state.farmsCheckedData.length > 1) {
        let farmAttrs = {
          name: "",
          coords: "",
          farmId: -1,
          cad:
            this.props.reportTypes[0] === "plan"
              ? 25
              : AGRYTOOLS.includes(this.props.reportTypes[0])
              ? 50
              : this.props.lastUsedCad,
          initials: "",
          type: "period",
          range: "daily",
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          user_points: this.state.farmsCheckedData,
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          min_prec: this.state.min_prec,
        };
        this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);
        this.toggleActive();
        if (this.props.reportActive !== true) {
          this.props.toggleReportContainer();
        }
        this.setState({
          numberOfFarms: this.state.farmsCheckedData.length,
          bhboxType: true,
        });
        const subtractYear = agriculturalYearSubtraction(
          this.props.visualizationType,
          this.props.report.report[0]
        );
        dates = this.getDatesToSameChart(
          this.props.reportTypes[0],
          subtractYear
            ? (new Date().getFullYear() - 1).toString()
            : new Date().getFullYear().toString()
        );
      } else {
        let farm = this.props.farms["farms"].filter(
          (farm) => farm.id === parseInt(data[0], 10)
        )[0];

        soil = farm.soil_texture;

        let farmAttrs = {
          name: farm.name,
          coords: farm.coords,
          farmId: farm.id,
          cad:
            this.props.reportTypes[0] === "plan"
              ? 25
              : AGRYTOOLS.includes(this.props.reportTypes[0])
              ? 50
              : farm.cad,
          initials: farm.initials,
          type: "period",
          range: "daily",
          initial_date: "1980",
          final_date: (new Date().getFullYear() + 1).toString(),
          tb: this.props.tBasal,
          visualization_type: this.props.visualizationType,
          min_prec: this.state.min_prec,
        };
        this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
        this.props.setReportTypes(this.props.reportTypes);
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);
        this.toggleActive();
        if (this.props.reportActive !== true) {
          this.props.toggleReportContainer();
        }

        const subtractYear = agriculturalYearSubtraction(
          this.props.visualizationType,
          this.props.report.report[0]
        );
        dates = this.getDatesToSameChart(
          this.props.reportTypes[0],
          subtractYear
            ? (new Date().getFullYear() - 1).toString()
            : new Date().getFullYear().toString()
        );

        this.props.setActiveFarm(parseInt(data[0], 10));
        this.selectedYear = this.baseLastYear;
        this.props.setMapYear(this.selectedYear);
        this.setState({
          numberOfFarms: this.state.farmsCheckedData.length,
          bhboxType: false,
        });
        this.updatedMap(dates, id, soil, event);
      }
    }

    if (dates !== undefined) {
      if(this.state.farmsCheckedData.length > 1 || id === "years") {
          this.props.toggleLeftLoader();
         return;
      }
      this.updatedMap(dates, id, soil, event);
    } 
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = (e) => {
    const format = /\d|[/]/; //regexp for accepting only digits or /
    const lastInput = e.target.value[e.target.value.length - 1];

    if (
      e.target.value.length >= 6 ||
      (!format.test(lastInput) && lastInput !== undefined)
    ) {
      return;
    }
    this.setState({
      [e.target.id]: e.target.value,
    });
    this.props.changeFilterParams({
      ...this.props.filterParams,
      [e.target.id]: e.target.value
    })
  };

  onDecendioChange = (e) => {
    const { name, value } = e.target;
    
    this.setState({
      [name]: value,
    });
    this.props.changeFilterParams({
      ...this.props.filterParams,
      [name]: value
    })
  };

  handleYearFilter = () => {
    if (this.props.graphicType === 'anomaly' && this.props.visualizationType === 'civil') {
      this.setState({
        startYear: this.state.selectStartYear,
        endYearAnomaly: this.state.selectEndYearAnomaly,
      });
    } else {
      if (this.props.visualizationType !== 'civil') {
        this.setState({
          startYearAgricultural: this.state.selectStartYearAgricultural,
          endYearAgricultural: this.state.selectEndYearAgricultural,
          endYearAgriculturalAnomaly: this.state.selectEndYearAgriculturalAnomaly,
        });
      } else {
        this.setState({
          startYear: this.state.selectStartYear,
          endYear: this.state.selectEndYear,
        });
      }
    }
  }

  handlePeriodFilter = () => {
    const { fromPeriod, toPeriod } = this.props.filterParams;
    const { graphicRange } = this.props;
    const [startDay, startMonth] = fromPeriod.split('/');
    const [endDay, endMonth] = toPeriod.split('/');
    const months = {
      '01': 'jan', '02': 'fev', '03': 'mar', '04': 'abr', '05': 'mai', '06': 'jun',
      '07': 'jul', '08': 'ago', '09': 'set', '10': 'out', '11': 'nov', '12': 'dez',
    };

    let start;
    let end;

    if (graphicRange === 'monthly') {
      start = months[startMonth];
      end = months[endMonth];
    } else if (graphicRange === 'decendial') {
      if (startDay < 11) {
        start = `${months[startMonth]}_1`;
      } else if (startDay < 21) {
        start = `${months[startMonth]}_2`;
      } else {
        start = `${months[startMonth]}_3`;
      }

      if (endDay < 11) {
        end = `${months[endMonth]}_1`;
      } else if (endDay < 21) {
        end = `${months[endMonth]}_2`;
      } else {
        end = `${months[endMonth]}_3`;
      }
    } else if (graphicRange === 'daily') {
      start = fromPeriod;
      end = toPeriod;
    }

    let fromDate;
    let toDate;

    if (this.props.visualizationType === 'civil') {
      fromDate = moment(this.props.filterParams.fromPeriod + "/2020", "DD-MM");
      toDate = moment(this.props.filterParams.toPeriod + "/2020", "DD-MM");
    } else {
      fromDate = startMonth >= 7
        ? moment(this.props.filterParams.fromPeriod + "/2020", "DD-MM-YYYY")
        : moment(this.props.filterParams.fromPeriod + "/2021", "DD-MM-YYYY");
      toDate = endMonth >= 7
        ? moment(this.props.filterParams.toPeriod + "/2020", "DD-MM-YYYY")
        : moment(this.props.filterParams.toPeriod + "/2021", "DD-MM-YYYY");
    }

    if (fromDate.isValid() && toDate.isValid() && toDate > fromDate) {
      this.props.changeFilterParams({
        ...this.props.filterParams,
        startDate: start,
        endDate: end
      })
      // this.setState({ startDate: start, endDate: end });
    } else if (toDate < fromDate) {
      const toasterParams = {
        title: "Período Inválido!",
        message: "A data final deve ser maior que a data inicial.",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    } else {
      const toasterParams = {
        title: "Data Inválida!",
        message: "Por favor insira datas válidas nos campos",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    }
  };

  onFilterClick = () => {
    let startingIndex;
    let endingIndex;
    let fromDate;
    let toDate;

    if (this.props.visualizationType === 'civil') {
      fromDate = moment(this.props.filterParams.fromPeriod + "/2020", "DD-MM");
      toDate = moment(this.props.filterParams.toPeriod + "/2020", "DD-MM");

      startingIndex = fromDate.get('DDD');
      endingIndex = toDate.get('DDD');
    } else {
      const startingMonth = this.props.filterParams.fromPeriod.split('/')[1];
      const endingMonth = this.props.filterParams.toPeriod.split('/')[1];
      const JULY_FIRST_INDEX = 182;
      const DEC_LAST_INDEX = 184;

      fromDate = startingMonth >= 7
        ? moment(this.props.filterParams.fromPeriod + "/2020", "DD-MM-YYYY")
        : moment(this.props.filterParams.fromPeriod + "/2021", "DD-MM-YYYY");
      toDate = endingMonth >= 7
        ? moment(this.props.filterParams.toPeriod + "/2020", "DD-MM-YYYY")
        : moment(this.props.filterParams.toPeriod + "/2021", "DD-MM-YYYY");
      
      startingIndex = startingMonth >= 7
        ? fromDate.get('DDD') - JULY_FIRST_INDEX
        : fromDate.get('DDD') + DEC_LAST_INDEX;
      endingIndex = endingMonth >= 7
        ? toDate.get('DDD') - JULY_FIRST_INDEX
        : toDate.get('DDD') + DEC_LAST_INDEX;
    }

    if (fromDate.isValid() && toDate.isValid() && toDate > fromDate) {
      this.props.changeFilterParams({
        ...this.props.filterParams,
        fromIndex: startingIndex,
        toIndex: endingIndex
      })
      // this.setState({ fromIndex: startingIndex, toIndex: endingIndex });
    } else if (toDate < fromDate) {
      const toasterParams = {
        title: "Período Inválido!",
        message: "A data final deve ser maior que a data inicial.",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    } else {
      const toasterParams = {
        title: "Data Inválida!",
        message: "Por favor insira datas válidas nos campos",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    }
  };

  graphicTypeDescription = (type) => {
    switch (type) {
      case 'anomaly':
        return 'Anomalia';
      case 'cmp':
        return 'Comparação';
      case 'accumulate':
        return 'Acumulado';
      case 'climatology':
        return 'Climatologia';
      default:
        return '';
    }
  }

  weatherHeader = () => {
    const type_graphic = this.graphicTypeDescription(this.props.graphicType);
    if (this.props.reportTypes.includes("temperatura")) {
      if (this.props.graphicType === "period") {
        return "Temperatura do ar";
      } else if (this.props.graphicType === "climatology") {
        switch (this.state.temperatureFilter) {
          case "tmin":
            return `${type_graphic} ${type_graphic && '|'} Temperatura mínima do ar`;
          case "tmean":
            return `${type_graphic} ${type_graphic && '|'} Temperatura média do ar`;
          case "tmax":
            return `${type_graphic} ${type_graphic && '|'} Temperatura máxima do ar`;
          default:
            return `${type_graphic} ${type_graphic && '|'} Temperatura do ar`;
        }
      } else if (
        this.props.graphicType === "cmp" ||
        this.props.graphicType === "anomaly"
      ) {
        if (this.state.temperatureControl === "tmax") {
          return `${type_graphic} ${type_graphic && '|'} Temperatura máxima do ar`;
        } else if (this.state.temperatureControl === "tmean") {
          return `${type_graphic} ${type_graphic && '|'} Temperatura média do ar`;
        } else {
          return `${type_graphic} ${type_graphic && '|'} Temperatura do ar`;
        }
      }
    } else if (this.props.reportTypes.includes("rs")) {
      return `${type_graphic} ${type_graphic && '|'} Radiação solar`;
    } else if (this.props.reportTypes.includes("gd")) {
      return `${type_graphic} ${type_graphic && '|'} Graus-dia`;
    } else if (this.props.reportTypes.includes("prec")) {
      return `${type_graphic} ${type_graphic && '|'} Chuva`;
    } else if (this.props.reportTypes.includes("u2")) {
      return `${type_graphic} ${type_graphic && '|'} Velocidade do Vento a 2m`;
    } else if (this.props.reportTypes.includes("ur")) {
      return `${type_graphic} ${type_graphic && '|'} Umidade relativa do ar`;
    } else if (this.props.reportTypes.includes("etp")) {
      return `${type_graphic} ${type_graphic && '|'} Evapotranspiração Potencial`;
    } else if (this.props.reportTypes.includes("excdef")) {
      if (this.state.temperatureControl === "def") {
        return "Deficiência Hídrica no decêndio";
      } else {
        return "Excedente Hídrico no decêndio";
      }
    } else if (this.props.reportTypes.includes("etretp")) {
      if (this.state.temperatureControl === "etp") {
        return "Evapotranspiração Potencial";
      } else if (this.state.temperatureControl === "etr") {
        return "Evapotranspiração Real";
      } else {
        return "Evapotranspiração Real e Potencial";
      }
    } else if (this.props.reportTypes.includes("arm")) {
      return "Armazenamento";
    } else if (this.props.reportTypes.includes("atrmax")) {
      return "ATRMax";
    } else if (this.props.reportTypes.includes("heat_wave")) {
      return "Onda de Calor";
    } else if (this.props.reportTypes.includes("frost_risk")) {
      return "Geada";
    } else if (this.props.reportTypes.includes("rainy_days")) {
      if (this.state.min_prec === "0") {
        return "Dias com Chuva - Precipitação > 0mm";
      } else {
        return "Dias com Chuva - Precipitação >= 5mm";
      }
    }
  };

  toggleClimatologyComparison = () => {
    this.setState({
      climatologyCompare: !this.state.climatologyCompare,
    });
  };

  showScheduleIcon = () => {
    let show = false;
    const reportTypes = [
      "temperatura",
      "rs",
      "gd",
      "u2",
      "prec",
      "ur",
      "etp",
      'heat_wave',
      'rainy_days',
      'frost_risk'
    ]
    if (
      reportTypes.includes(this.props.reportTypes[0])
    ) {
      if (
        this.props.graphicRange !== "annualy" &&
        ["period", "cmp"].includes(this.props.graphicType)
      ) {
        show = true;
      }
    } else if (this.props.reportTypes[0] === "arm") {
      return true;
    }
    return show;
  };

  onSchedule = () => {
    this.toggleSchedule();

    const data = {
      farm_id: this.props.activeFarm,
      period: this.state.scheduledDay,
      day_time: this.state.scheduledPeriod,
      report_args: {
        visualization_type: this.props.visualizationType,
        variable: this.props.reportTypes[0],
        type: this.props.graphicType,
        range: this.props.graphicRange,
        tb: this.props.tBasal,
      },
    };
    this.props.createSchedule(data);
  };
  //#endregion

  render() {
    const { activeFarm, reportTypes, graphicType, graphicRange, visualizationType } = this.props;

    return (
      <Fragment>
        <section
          className={classnames("report--container", {
            active: this.props.active,
            "full-screen": this.props.fullScreen,
            visible: this.props.reportType !== "",
            "toggler-hide":
              !this.props.active && this.props.report === undefined,
          })}
        >
          <span
            className={classnames(
              "container--toggler container--toggler-left valign-wrapper",
              { "container--toggler-left-not-active": !this.props.active }
            )}
            onClick={this.toggleActive}
          >
            <i className="material-icons">play_arrow</i>
          </span>
          <header className="row bhbox-header">
            <div title="Fullscreen" className="valign-wrapper">
              <span
                className="valign-wrapper"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.toggleFullScreen();
                }}
              >
                <i className="material-icons">
                  {this.props.fullScreen
                    ? "picture_in_picture_alt"
                    : "aspect_ratio"}
                </i>
              </span>
            </div>
            <div className={classnames("header-div")}>
              <div className={classnames("header-label")}>
                <label>{this.setHeaderLabel()}</label>
              </div>
              {this.renderDropdowns()}
            </div>
            {this.props.report !== undefined ? (
              <Fragment>
                {this.showScheduleIcon() ? (
                  <Fragment>
                    <div
                      className="header-graphic"
                      id="schedule-mail"
                      style={{ marginRight: "4px" }}
                    >
                      <span title="Agendar email" onClick={this.toggleSchedule}>
                        <i className="material-icons">mail</i>
                      </span>
                    </div>
                    { this.state.showSchedule && (
                      <EmailModal
                        showSchedule={ this.state.showSchedule }
                        toggleSchedule={ this.toggleSchedule }
                        activeFarm={ activeFarm }
                        reportType={ reportTypes[0] }
                        graphicType={ graphicType }
                        graphicRange={ graphicRange }
                        visualizationType={ visualizationType }
                      />
                    ) }
                  </Fragment>
                ) : null}
                {this.props.graphicType === "climatology" && (
                  <div
                    className="header-graphic"
                    style={{ marginRight: "4px" }}
                  >
                    <span
                      title={
                        this.state.climatologyCompare
                          ? "Esconder comparação"
                          : "Exibir comparação"
                      }
                      onClick={this.toggleClimatologyComparison}
                    >
                      <i className="material-icons">
                        {this.state.climatologyCompare
                          ? "show_chart"
                          : "multiline_chart"}
                      </i>
                    </span>
                  </div>
                )}
                {
                  this.props.reportTypes[0] === "atrmax" && (
                    <SelectGraphicAtrMax
                      changeGraphic={this.changeGraphic}
                      currentGraphic={this.state.currentGraphic}
                    />
                  )
                }
                {this.props.reportTypes[0] !== "bhbox" &&
                !AGRYTOOLS.includes(this.props.reportTypes[0]) &&
                this.props.reportTypes[0] !== "fmaac" ? (
                  <div
                    className="header-graphic"
                    style={{ marginRight: "4px" }}
                  >
                    <span
                      title={
                        this.props.showTooltips
                          ? "Esconder caixa de informações"
                          : "Exibir caixa de informações"
                      }
                      onClick={this.props.toggleTooltips}
                    >
                      <i className="material-icons">
                        {this.props.showTooltips
                          ? "visibility_off"
                          : "visibility"}
                      </i>
                    </span>
                  </div>
                ) : null}
                {this.showExportDataButton() ? (
                  <DownloadXLS
                    marginRight={undefined}
                    headers={this.getDataToExportHeaders()}
                    data={this.getDataToExport()}
                  />
                ) : null}
                {this.props.reportTypes[0] === "atrmax" ? (
                  <div
                    style={{
                      marginTop: !this.state.download ? 3 : 15,
                      marginLeft: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      maxWidth: !this.state.download ? 30 : 35,
                      maxHeight: !this.state.download ? 30 : 2,
                    }}
                  >
                    {!this.state.download
                      ? (
                        <Button
                          onClick={() => {
                            this.setState({
                              download: true,
                            });
                            this.exportAtrMaxData();
                          }}
                        >
                          <img
                            style={{
                              maxWidth: 30,
                              maxHeight: 30
                            }}
                            src={"/images/excel-icon.jpg"}
                            alt="Exportar Dados"
                          />
                        </Button>
                      )
                      : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            fontSize: '11px',
                          }}
                        >
                          baixando...
                          <LinearProgress sx={{ maxHeight: '3px', minWidth: 30 }} />
                        </Box>
                      )}
                  </div>
                ) : null}
              </Fragment>
            ) : null}
          </header>
          {this.renderFarmInfo()}
          {this.renderReport()}
        </section>
        {this.props.atrHelp ? (
          <AtrMaxHelp close={this.props.toggleAtrHelp} />
        ) : null}
      </Fragment>
    );
  }
}

export default Report;
